import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { Field } from "formik"
import MgcSelectFieldV2 from "../../global/mgcSelectFieldV2"
import MgcRadioToggleButtonGroupV2 from "../../global/mgcRadioToggleButtonGroupV2"
import MgcNewDateFieldV2 from "../../global/mgcNewDateFieldV2"
import MgcRadioToggleButtonV2 from "../../global/mgcRadioToggleButtonV2"
import { hasOnlyNumbers } from "../../../utils/regexTests"

const QuotationAGCOLTERV2 = ({
  errors,
  dirty,
  values,
  touched,
  setFieldValue,
  landing,
  value,
  handleChange,
  resetErrorsAfterSubmitWithErrors,
  isErrorSubmitting,
  showErrorOnValidation
}) => {
  useEffect(() => {
    if (!values.cpEmployeurAngentTerritotrial) {
      values.cpEmployeurAngentTerritotrial = ""
    }
  }, [])

  const dejaCouvert = ({ dejaCouvert }) => {
    return dejaCouvert && (dejaCouvert === true || dejaCouvert === "true")
  }

  return (
    <>
      <Row>
        <Col xs={12} className={landing && "landing-agentTerritoral"}>
          <div className="form-row">
            <label className="col-12">Employeur (facultatif)</label>
            <div className="form-group col-11">
              <Field
                type="text"
                name="employeurAgentTerritotrial"
                className="form-control"
                placeholder="Ex : Mairie"
                onChange={handleChange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="form-row">
            <label className="col-12">
              Code postal de l'employeur (facultatif)
            </label>
            <div className="form-group col-11">
              <Field
                type="text"
                name="cpEmployeurAngentTerritotrial"
                className="form-control"
                placeholder="Ex : 75005"
                onChange={e => {
                  if (hasOnlyNumbers(e.target.value)) {
                    setFieldValue(e.target.name, e.target.value)
                  }
                }}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="question-y-n">
        <Col xs={12} className="quotation-radio">
          <MgcRadioToggleButtonGroupV2
            label="Avez-vous déjà été couvert par une complémentaire santé labellisée ? *"
            id="deja-couvert"
            error={errors.dejaCouvert}
            touched={touched.dejaCouvert}
            withoutOffset={true}
            value={value}
            dirty={landing && true}
            isErrorSubmitting={isErrorSubmitting}
          >
            <Field
              component={MgcRadioToggleButtonV2}
              name="dejaCouvert"
              id="true"
              label={"Oui"}
              onChangeWithReset={handleChange}
            />
            <Field
              component={MgcRadioToggleButtonV2}
              name="dejaCouvert"
              id="false"
              label={"Non"}
              onChangeWithReset={handleChange}
            />
          </MgcRadioToggleButtonGroupV2>
        </Col>
      </Row>
      {dejaCouvert(values) && (
        <>
          <Row>
            <Col xs={12}>
              <MgcNewDateFieldV2
                dirty={dirty}
                errors={errors}
                name="dateFinDernierContrat"
                className="form-control form-row-step-2"
                withoutOffset={true}
                label={"Date de fin du dernier contrat labelisée *"}
                setFieldValue={setFieldValue}
                values={values}
                isErrorSubmitting={isErrorSubmitting}
                resetErrorsAfterSubmitWithErrors={
                  resetErrorsAfterSubmitWithErrors
                }
                showErrorOnValidation
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MgcSelectFieldV2
                dirty={dirty}
                name="tauxMajorationDernierContrat"
                label="Taux de majoration du dernier contrat labellisé"
                errors={errors}
                firstOption="- Sélectionner le taux -"
                className="form-control form-row-step-2"
                setFieldValue={setFieldValue}
                onChange={resetErrorsAfterSubmitWithErrors}
                table={[
                  { id: "0%", libelle: "0 %" },
                  { id: "2%", libelle: "2 %" },
                  { id: "4%", libelle: "4 %" },
                ]}
              />
            </Col>
          </Row>
        </>
      )}
      {values.dejaCouvert && !dejaCouvert(values) && (
        <Row>
          <Col xs={12}>
            <MgcNewDateFieldV2
              dirty={dirty}
              errors={errors}
              name="dateEntreeFonctionPublique"
              className="form-control form-row-step-2"
              withoutOffset={true}
              label={"Date d'entrée dans la fonction publique *"}
              setFieldValue={setFieldValue}
              values={values}
              isErrorSubmitting={isErrorSubmitting}
              resetErrorsAfterSubmitWithErrors={
                resetErrorsAfterSubmitWithErrors
              }
              showErrorOnValidation
            />
          </Col>
        </Row>
      )}
    </>
  )
}

export default QuotationAGCOLTERV2
