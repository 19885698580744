import React from "react"
import { Field } from "formik"
import Axios from "axios"
import PropTypes from "prop-types"
import MaskedInput from "react-text-mask"
import { maskStreetNumber } from "../../../services/global/fieldMasks"
import { MgcgestionTokenService } from "../../../services/mgcgestionTokenService"
import MgcTextFieldV2 from "../../global/mgcTextFieldV2"
import MgcCpVilleV2 from "../../global/mgcCpVilleV2"
import MgcAutoCompleteV2 from "../../global/mgcAutocompleteV2"
import "./quotationAdresseV2.css"
import AdresseApptEntreeV2 from "../../global/adresseApptEntreeV2"
import classNames from "classnames"

class QuotationAdresseV2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numeroLatinList: [],
      typeVoieList: [],
      paysList: [],
      filteredPaysList: [],
      aelCountryWarningHidden: true,
      showFacultativeFields: false,
    }
    this.handlePaysChange = this.handlePaysChange.bind(this)
    this.setAelCountryWarningHidden = this.setAelCountryWarningHidden.bind(this)
    this.handlePaysSelect = this.handlePaysSelect.bind(this)
    this.handleClickFacultativeFields = this.handleClickFacultativeFields.bind(
      this
    )
  }

  componentDidMount() {
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/numero-latins`
    /*        URL = "http://localhost:8080/api/public/numero-latins"*/
    Axios.get(URL).then(result => {
      this.setState({ numeroLatinList: result.data })
    })
    URL = `${
      process.env.GATSBY_MGCGESTION_API_URL
    }/public/type-voies?size=999&sort=libelle,asc`
    /*        URL = "http://localhost:8080/api/public/type-voies"*/
    Axios.get(URL).then(result => {
      this.setState({ typeVoieList: result.data })
    })
    URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/pays/autocomplete`
    /*        URL = "http://localhost:8080/api/public/pays/autocomplete"*/
    Axios.get(URL).then(result => {
      this.setState({ paysList: result.data })
    })

    const { quotationTns, setFieldValue, values } = this.props
    if (quotationTns) {
      setFieldValue("adresseCodePostal", values.codePostalTNS)
    }
  }

  handlePaysChange(value) {
    let newPaysList = []
    if (value && value.length && value.length >= 2) {
      newPaysList = this.state.paysList.filter(p => {
        return p.libelle.toUpperCase().startsWith(value.toUpperCase())
      })
    } else if (!value) {
    }
    this.setState({ filteredPaysList: newPaysList })
  }

  handlePaysSelect(value, name) {
    const { resetErrorsAfterSubmitWithErrors, validCountryAel } = this.props
    if (validCountryAel) {
      this.setAelCountryWarningHidden(value)
    }
    resetErrorsAfterSubmitWithErrors(name)

  }
  setAelCountryWarningHidden(value) {
    const { paysList } = this.state
    const { setFieldValue } = this.props
    if (value) {
      const validAel = paysList.some(p => p.libelle === value && p.ael === true)
      setFieldValue("countryValidAel", validAel)
      this.setState({
        aelCountryWarningHidden: validAel,
      })
    }
  }

  handleClickFacultativeFields() {
    this.setState({ showFacultativeFields: !this.state.showFacultativeFields })
  }

  render() {
    const {
      errors,
      onChange,
      dirty,
      setFieldValue,
      values,
      withoutOffset,
      needLibelleOfTypeVoie,
      withoutCountry,
      handleChange,
      isErrorSubmitting,
      resetErrorsAfterSubmitWithErrors
    } = this.props

    const { showFacultativeFields } = this.state
    return (
      <>
        <div className={withoutOffset ? "" : "col-11"}>
          <div className="form-row">
            <div className={withoutOffset ? "" : "col-1"} />
            <div className={withoutOffset ? "col-11 col-md-5 form-group" : "col-10 col-md-4 form-group"}>
              <label>Numéro (facultatif)</label>
              <Field
                name="adresseNumero"
                render={({ field }) => {
                  return (
                    <MaskedInput
                      mask={maskStreetNumber}
                      {...field}
                      className="form-control mb-0"
                      placeholder="Ex : 212"
                      guide={false}
                      id="adresseNumero"
                      onChange={handleChange}
                    />
                  )
                }}
              />
            </div>
            <div className={`col form-group ${withoutOffset ? "" : "offset-1"} offset-md-0`}>
              <label>Type de voie *</label>
              <Field
                name="adresseTypeVoie"
                component="select"
                className={classNames("form-control", {"is-invalid": errors.adresseTypeVoie && isErrorSubmitting})}
                onChange={handleChange}
              >
                <option value="">- Choisir un type de voie -</option>
                {this.state.typeVoieList.map(e => {
                  return (
                    <option
                      value={needLibelleOfTypeVoie ? e.libelle : e.id}
                      key={e.libelle}
                    >
                      {e.libelle}
                    </option>
                  )
                })}
              </Field>
              <div className="invalid-feedback">
                {errors.adresseTypeVoie}
              </div>
            </div>
            <div className="col-1 mt-30px">
              <span
                className={`${
                  errors["adresseTypeVoie"] || !dirty
                    ? "fa fa-times text-danger"
                    : ""
                } fa-2x`}
              />
            </div>
          </div>

          <div className={withoutOffset ? "" : "offset-1"}>
            <MgcTextFieldV2
              errors={errors}
              dirty={dirty}
              name="adresseVoie"
              label="Nom de la voie *"
              placeholder={"Ex : Jules Ferry"}
              withoutOffset={withoutOffset}
              maxLength={25}
              isErrorSubmitting={isErrorSubmitting}
              onChange={handleChange}
            />
          </div>
          <MgcCpVilleV2
            nameCP="adresseCodePostal"
            labelCp="Code postal *"
            labelVille="Ville *"
            nameVille="adresseVille"
            errors={errors}
            dirty={dirty}
            setFieldValue={setFieldValue}
            values={values}
            placeholderCp="Ex : 75001"
            withoutOffset={withoutOffset}
            initValue={values.tns ? values.codePostalTNS : false}
            disabled={values.tns}
            handleChangeWithReset={handleChange}
            isErrorSubmitting={isErrorSubmitting}
            resetErrorsAfterSubmitWithErrors={resetErrorsAfterSubmitWithErrors}
          />
          {withoutCountry ? (
            ""
          ) : (
            <MgcAutoCompleteV2
              className={"pays"}
              withoutOffset={withoutOffset}
              dirty={dirty}
              errors={errors}
              label="Pays *"
              placeholder="Ex : France"
              name="adressePays"
              values={values}
              items={this.state.filteredPaysList}
              onChange={this.handlePaysChange}
              labelField="libelle"
              valueField="libelle"
              setFieldValue={setFieldValue}
              onSelect={this.handlePaysSelect}
              classe={"quot-step-3"}
              isErrorSubmitting={isErrorSubmitting}
            />
          )}
          <div className="form-row mb-2">
            <div className={withoutOffset ? "" : "col-11"}>
              <span
                className="complement-adresse font-size-15"
                onClick={this.handleClickFacultativeFields}
              >
                Complément d'adresse
              </span>
              &nbsp;(facultatif)
            </div>
          </div>

          <div hidden={!showFacultativeFields}>
            <AdresseApptEntreeV2
              dirty={dirty}
              errors={errors}
              withoutOffset={withoutOffset}
            />

            <div className="form-row margin-bottom-15">
              <div className={withoutOffset ? "" : "col-1"} />
              <div className="col-4 form-group">
                <label>N° latin</label>
                <Field
                  name="adresseNumeroLatin"
                  component="select"
                  className="form-control mb-0"
                >
                  <option value="">- N° latin -</option>
                  {this.state.numeroLatinList.map(e => {
                    return (
                      <option value={e.id} key={e.id}>
                        {e.libelle}
                      </option>
                    )
                  })}
                </Field>
              </div>
              <div className="col form-group">
                <label>Complément Alpha</label>
                <Field
                  type="text"
                  name="adresseComplementAlpha"
                  className={classNames("form-control complementAlpha mb-0", {"is-invalid" : errors.adresseComplementAlpha && isErrorSubmitting})}
                  placeholder="Ex : B"
                  maxLength="1"
                  onChange={e => {
                    setFieldValue(
                      "adresseComplementAlpha",
                      e.target.value.toUpperCase()
                    )
                    resetErrorsAfterSubmitWithErrors("adresseComplementAlpha")
                  }}
                />
                <div className="invalid-feedback">{errors.adresseComplementAlpha}</div>
              </div>
              <div className="col-1 mt-30px">
                <span
                  className={`${
                    errors["adresseNumeroLatin"] ||
                    errors["adresseComplementAlpha"] ||
                    !dirty
                      ? "fa-times text-danger"
                      : ""
                  } fa-2x fa`}
                />
              </div>
            </div>
            <div className={withoutOffset ? "" : "offset-1"}>
              <MgcTextFieldV2
                label="Lieu-dit"
                errors={errors}
                dirty={dirty}
                name="adresseLieuDit"
                placeholder="Ex : Amarens"
                withoutOffset={withoutOffset}
                maxLength={38}
                isErrorSubmitting={isErrorSubmitting}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

QuotationAdresseV2.propTypes = {
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  validCountryAel: PropTypes.bool,
}
export default QuotationAdresseV2
