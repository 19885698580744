import React from "react"
import { Row, Col } from "react-bootstrap"
import { Field } from "formik"
import MaskedInput from "react-text-mask"
import MgcSelectFieldV2 from "../../global/mgcSelectFieldV2"
import QuotationConjointV2 from "./quotationConjointV2"

class QuotationChildrenV2 extends React.Component {
  render() {
    const {
      tab,
      values,
      dirty,
      errors,
      tabRa,
      setFieldValue,
      isErrorSubmitting,
      resetErrorsAfterSubmitWithErrors,
      handleChangeWithReset,
    } = this.props
    return (
      <div>
        <Row>
          <Col xs={11}>
            <label>Nombre d'enfants de moins d'1 an</label>
          </Col>
          <Col xs={11} className={"form-group"}>
            <Field
              name="nbEnfantsMoisn1an"
              type="text"
              className="form-control mb-0"
              render={({ field }) => {
                return (
                  <MaskedInput
                    mask={[/\d/]}
                    {...field}
                    className="form-control mb-0"
                    placeholder={""}
                    guide={false}
                    id={"nbEnfantsMoisn1an"}
                    onChange={handleChangeWithReset}
                  />
                )
              }}
            />
          </Col>
          <Col xs={11} className="m-bottom">
            <span>
              <em>
                Leur cotisation est gratuite jusqu'au mois de leur 1er
                anniversaire inclus.
              </em>
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={11} className={"form-group"}>
            <label>Nombre d'enfants entre 1 et 25 ans</label>
            <Field
              name="nbEnfantsPlus1an"
              component="select"
              className="form-control"
              onChange={handleChangeWithReset}
            >
              <option key="plop" value="null">
                - 0 enfant -
              </option>
              {tab.map(e => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
          </Col>
        </Row>
        {values.nbEnfantsPlus1an == 1 || values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectFieldV2
            dirty={dirty}
            label={`${
              values.nbEnfantsPlus1an > 1
                ? "Régime d'assurance maladie du 1er enfant (le + âgé) *"
                : "Régime de sécurité sociale *"
            }`}
            name="regimeAssuranceMalEnfant1"
            errors={errors}
            firstOption="- Sélectionner le régime -"
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
            isErrorSubmitting={isErrorSubmitting}
            onChange={() => {resetErrorsAfterSubmitWithErrors("regimeAssuranceMalEnfant1")}}
          />
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectFieldV2
            dirty={dirty}
            name="regimeAssuranceMalEnfant2"
            errors={errors}
            label="Régime d'assurance maladie du 2ème enfant *"
            firstOption="- Sélectionner le régime -"
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
            isErrorSubmitting={isErrorSubmitting}
            onChange={() => {resetErrorsAfterSubmitWithErrors("regimeAssuranceMalEnfant2")}}
          />
        ) : (
          ""
        )}
      </div>
    )
  }
}
export default QuotationChildrenV2
