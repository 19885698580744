import * as React from "react"
import { Formik } from "formik"
import QuotationValidation from "../../quotationValidation"
import { Row, Col } from "react-bootstrap"
import MgcStepper from "../../mgcStepper"
import Axios from "axios"
import Spinner from "react-bootstrap/Spinner"
import QuotToAelMapper from "../../../services/quotToAelMapper"
import QuotationBesoinsMapper from "../../../services/quotationBesoinsMapper"
import { MgcgestionTokenService } from "../../../services/mgcgestionTokenService"
import QuotationTNSMapper from "../../../services/quotationTNSMapper"
import QuotationAgentTerritorialMapper from "../../../services/quotationAgentTerritorialMapper"
import Moment from "moment"
import GAnalyticsService from "../../../services/gAnalyticsService"
import classNames from "classnames"
import { isEdealAvailable } from "../../../services/devis/edealService"
import "./quotationWizardV2.css"
import "../../global/commonAelQuotation.css"
import { ATInternetTagService } from "./../../../services/global/ATInternetService"

class QuotationWizardV2 extends React.Component {
  static Step = ({ children }) => children

  initialValues = {
    dateNaissance: null,
    regimeAssuranceMal: "",
    situationPro: "",
    debutContrat: null,
    depassement: "",
    auditif: "",
    hospi: "",
    prothesesDentaires: "",
    lunettesLentilles: "",
    politiquementExpose: "",
    conjointChecked: "",
    childrenChecked: false,
    regimeAssuranceMalConjoint: "",
    dateNaissanceConjoint: "",
    situationProConjoint: "",
    salariesTNS: "",
    civilite: "",
    adherentNom: "",
    adherentPrenom: "",
  }
  errorSend
  steps
  toKnowStep
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      values: props,
      formules: [],
      waiting: false,
      offer: "",
      redirect: false,
      errorHTTP: "",
      waitingForStep2: false,
      codeGamme: "",
      eDealAvailable: false,
      successSubmitCount: 0,
      allSubmitCount: 0,
      errorValidationOnSubmit: false,
      firstPartOfStep0isValid: false,
      modeAdhesion: "FULL",
      classButtonb: "col-12 col-md-8 offset-md-2"
    }
    this.steps = [
      {
        title: "Ma situation",
        onClick: () => this.goToStep(0),
      },
      {
        title: "Mes garanties",
        onClick: () => this.goToStep(1),
      },
      {
        title: "Mes coordonnées personnelles",
        onClick: () => this.goToStep(2),
      },
      {
        title: "Mon devis",
        onClick: () => this.goToStep(3),
      },
    ]

    this.goToStep = this.goToStep.bind(this)
  }
  componentDidMount() {
    this.setState({ wainting: false })
    if (sessionStorage.getItem("devisStepOne")) {
      this.setState({ waitingForStep2: true })
      this.getFormula(JSON.parse(sessionStorage.getItem("devisStepOne")))
    }
  }
  getFormula(value = 0) {
    let values = this.state.values
    if (value !== 0) {
      values = value
    }
    /*         const URL_OFFER = "http://localhost:8080/api/public/offres/by-devis"
    const URL = "http://localhost:8080/api/public/oscar/demande-echeancier-devis"*/
    const URL_OFFER = `${process.env.GATSBY_MGCGESTION_API_URL}/public/offres/by-devis`
    const URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/oscar/demande-echeancier-devis`
    let entrepriseTNS = null
    if (values.tns) {
      entrepriseTNS = {
        codePostal: values.codePostalTNS,
        ville: values.villeTNS,
        nom: values.nomEntrepriseTNS,
        salaries: values.salariesTNS,
        besoins: values.besoinTNS,
      }
    }
    let conjoint
    if (values.conjointChecked) {
      conjoint = {
        dateNaissance: Moment(values.dateNaissanceConjoint).format(
          "YYYY-MM-DD"
        ),
        regimeAssurance: values.regimeAssuranceMalConjoint,
        situationProfessionnelle: values.situationProConjoint,
      }
    }
    let enfant1
    const d = new Date()
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 1) {
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 10))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant1 = {
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant1,
        situationProfessionnelle: "",
      }
    }
    let enfant2
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 2) {
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 11))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant2 = {
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant2,
        situationProfessionnelle: "",
      }
    }
    let agentTerritorial = null
    if (values.agentTerritorial) {
      agentTerritorial = {
        codePostalEmployeur: values.cpEmployeurAngentTerritotrial,
        employeur: values.employeurAgentTerritotrial,
      }
    }
    Axios.post(
      URL_OFFER,
      {
        agentTerritorial: agentTerritorial,
        entrepriseTNS: entrepriseTNS,
        nbEnfantsPlus1an: values.nbEnfantsPlus1an || 0,
        dateEffet: values.debutContrat,
        nbEnfantsMoisn1an: values.nbEnfantsMoisn1an || 0,
        beneficiaire: {
          dateNaissance: Moment(values.dateNaissance).format("YYYY-MM-DD"),
          regimeAssurance: values.regimeAssuranceMal,
          situationProfessionnelle: values.situationPro,
          consultationDepHonoraire: values.depassement,
          lunette: values.lunettesLentilles,
          soisnDentaire: values.prothesesDentaires,
          hospitalisation: values.hospi,
          audition: values.auditif,
          politiquementExpose: values.politiquementExpose,
        },
        conjoint: conjoint,
        enfant1: enfant1,
        enfant2: enfant2,
      }
    )
      .then(result => {
        this.setState({ offer: result })
      })
      .then(() => {
        Axios.post(
          URL,
          {
            agentTerritorial: agentTerritorial,
            entrepriseTNS: entrepriseTNS,
            nbEnfantsPlus1an: values.nbEnfantsPlus1an || 0,
            dateEffet: values.debutContrat,
            nbEnfantsMoisn1an: values.nbEnfantsMoisn1an || 0,
            beneficiaire: {
              dateNaissance: Moment(values.dateNaissance).format("YYYY-MM-DD"),
              regimeAssurance: values.regimeAssuranceMal,
              situationProfessionnelle: values.situationPro
            },
            conjoint: conjoint,
            enfant1: enfant1,
            enfant2: enfant2,
            consultationDepHonoraire: values.depassement,
            lunette: values.lunettesLentilles,
            soisnDentaire: values.prothesesDentaires,
            hospitalisation: values.hospi,
            audition: values.auditif,
            politiquementExpose: values.politiquementExpose,
          }
        )
          .then(result => {
            this.setState({ wainting: false })

            if(result.data.modeAdhesion ==="SEND_DEVIS_ONLY"){
              this.setState({ classButtonb: "col-12 col-md-8 offset-md-2" })
            }else{
              this.setState({ classButtonb: "col-12 col-md-8 offset-md-2" })
            }
            

            this.setState({ modeAdhesion: result.data.modeAdhesion })
            let formul = result.data.propositions.sort((a, b) =>
              a.formule.ordre > b.formule.ordre
                ? 1
                : b.formule.ordre > a.formule.ordre
                ? -1
                : 0
            )
            if (formul && formul.length > 0) {
              const { gamme } = formul[0]
              const codeGamme = gamme.code
              this.setState({ codeGamme: codeGamme })
              GAnalyticsService.sendEvent(
                "Devis santé",
                "Tarification",
                codeGamme
              )
            }
            this.setState({ formules: formul })
            this.setState({ values: values })
            this.next(values)
          })
          .catch(error => {
            this.setState({ step: 4 })
          })
      })
  }

  quotationSent() {
    const values = this.state.values
    /*         const URL = "http://localhost:8080/api/public/envoi-devis"*/
    const URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/envoi-devis`
    let conjoint
    if (values.conjointChecked) {
      conjoint = {
        civilite: values.conjointCivilite === "homme" ? "monsieur" : "madame",
        dateNaissance: Moment(values.dateNaissanceConjoint).format(
          "YYYY-MM-DD"
        ),
        regimeAssurance: values.regimeAssuranceMalConjoint,
        situationProfessionelle: values.situationProConjoint,
        nom: values.conjointNom,
        prenom: values.conjointPrenom,
      }
    }
    let enfants
    let enfant1
    const d = new Date()
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 1) {
      enfants = []
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 10))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant1 = {
        civilite: "madame",
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant1,
        situationProfessionnelle: "",
        nom: "ANONYME-ENFANT-1",
        prenom: "ANONYME-ENFANT-1",
      }
      enfants.push(enfant1)
    }
    let enfant2
    if (values.childrenChecked && values.nbEnfantsPlus1an >= 2) {
      let date = new Date(d.getFullYear(), d.getMonth(), 1)
      let dat = new Date(date.setYear(date.getFullYear() - 11))
      let fr_date = dat.toLocaleDateString("fr-FR")
      fr_date = fr_date.split("/")
      let us_date = fr_date.reverse().join("-")
      enfant2 = {
        civilite: "madame",
        dateNaissance: us_date,
        regimeAssurance: values.regimeAssuranceMalEnfant2,
        situationProfessionnelle: "",
        nom: "ANONYME-ENFANT-2",
        prenom: "ANONYME-ENFANT-2",
      }
      enfants.push(enfant2)
    }
    this.setState({ waiting: true })
    Axios.post(
      URL,
      {
        dateEffet: values.debutContrat,
        enfants: enfants,
        codeGamme: this.state.formules[0]
          ? this.state.formules[0].gamme.cleOscar
          : "",
        titulaire: {
          civilite: values.civilite === "homme" ? "monsieur" : "madame",
          nom: values.adherentNom,
          prenom: values.adherentPrenom,
          dateNaissance: Moment(values.dateNaissance).format("YYYY-MM-DD"),
          regimeAssurance: values.regimeAssuranceMal,
          situationProfessionelle: values.situationPro,
        },
        conjoint: conjoint,
        byEmail: values.mailChecked,
        email: values.email,
        byCourrier: values.courrierChecked,
        adresse: {
          complement: "",
          lieuDit: values.adresseLieuDit,
          adresse1: values.appartement,
          adresse2: values.batiment,
          numero: values.adresseNumero,
          batiment: values.batiment,
          numeroLatin: values.adresseNumeroLatin || "",
          compalpha: values.adresseComplementAlpha || "",
          typeVoie: values.adresseTypeVoie,
          voie: values.adresseVoie,
          adresse4: "",
          codePostal: values.adresseCodePostal,
          ville: values.adresseVille,
          pays: values.adressePays,
        },
        telephone: values.telephone,
        preference: {
          offreMgc: values.offreMgc | false,
          offrePartenaire: values.offrePartenaire | false,
        },
        besoins: QuotationBesoinsMapper.toDTO(
          values.depassement,
          values.lunettesLentilles,
          values.prothesesDentaires,
          values.hospi,
          values.auditif,
          values.miniHospi,
          values.miniCouverture,
          values.couvertureZen
        ),
        ppe: values.politiquementExpose,
        entrepriseTNS: QuotationTNSMapper.toDTO(values),
        agentTerritorial: values.dejaCouvert
          ? QuotationAgentTerritorialMapper.toDTO(values)
          : null,
      }
    )
      .then(result => {
        GAnalyticsService.sendEvent(
          "Devis santé",
          "Envoi de devis",
          this.state.codeGamme
        )
        ATInternetTagService.sendPage({
          page: "etape4_mon_devis_validation",
          page_chapter1: "mutuelle_sante",
          page_chapter2: "devis",
          type_devis: values.codeGamme,
          type_produit: "sante",
          informations_offres_MGC: values.offreMgc ? "oui" : "non",
          informations_offres_partenaires: values.offrePartenaire
            ? "oui"
            : "non",
        })
        this.next(values)
      })
      .catch(error => {
        if(error.response.data.gdaalert){
          this.setState({ step: 5 });  
          this.setState({ waiting: false });        
        }else{
          this.setState({ step: 4 });
        }
      })
  }

  goToAel(values) {
    sessionStorage.setItem(
      "quotToAel",
      JSON.stringify(QuotToAelMapper.toAel(values))
    )
    window.location.assign("/mutuelle-sante/adhesion")
  }

  goToAdhesionPapier() {
    window.location.assign("/mutuelle-sante/adhesion-courrier/")
  }

  goToQuote() {
    window.location.assign("/mutuelle-sante/devis")
  }

  next = values =>
    this.setState(state => ({
      step: Math.min(state.step + 1, this.props.children.length - 1),
      waiting: false,
      values: values,
    }))

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props
    const { step, allSubmitCount } = this.state
    const isLastStep = step === React.Children.count(children) - 1
    this.setSuccessSubmitCount(allSubmitCount)
    this.props.thisStep()
    console.log('ddddddddddddd');
    console.log(step);
    if (step === 3) {
      this.goToAel(values)
    } else if (step === 0) {
      this.setState({ values: values })
      this.setState({ waiting: true })
      this.getFormula()
      isEdealAvailable().then(
        response => this.setState({ eDealAvailable: response.data }),
        () => this.setState({ eDealAvailable: false })
      )
    } else if (step === 2) {
      this.setState({ values: values })
      this.quotationSent()
      bag.setSubmitting(false)
    } else {
      this.next(values)
      bag.setSubmitting(false)
    }

    console.log("values.adherentNom + values.adherentPrenom");


    var idc = values.adherentNom + values.adherentPrenom;
    console.log(idc);

    if(values.adherentNom !== undefined) {

      window.dataLayer.push({
        event: 'infos',
        user_data: {
          user_id: ATInternetTagService.hashTheID(idc),
          user_first_name: values.adherentNom,
          user_last_name: values.adherentPrenom,
          user_mail: values.email,
          user_phone: values.portable
        }
      });

    }

  }
  navigateBack = () => {
    this.setState({ step: 0 })
    this.setState({ waiting: false })
    this.setState({ waitingForStep2: false })
    sessionStorage.removeItem("devisStepOne")
  }
  continueQuotation = (setValues, values) => {
    setValues(values)
  }

  goToStep = step => {
    if (step >= this.state.step) {
      return
    }
    if (step === 0) {
      this.navigateBack()
      return
    }
    this.setState({ step })
  }
  isErrorSubmitting = () => {
    const { successSubmitCount, allSubmitCount } = this.state
    // this.setState({allSubmitCount: submitCount});
    return successSubmitCount < allSubmitCount
  }

  setAllSubmitCount = submitCount => {
    this.setState({ allSubmitCount: submitCount })
  }

  setSuccessSubmitCount = submitCount => {
    this.setState({ successSubmitCount: submitCount })
  }

  resetErrorsAfterSubmitWithErrors = (setFormikState, errors, fieldName) => {
    if (this.isErrorSubmitting()) {
      let formikState = {
        errors: { ...errors },
      }
      formikState.errors[fieldName] = undefined

      setFormikState(formikState)
    }
  }

  resetErrorsAfterValidateWithErrors = (setFormikState, errors, fieldName) => {
    let formikState = {
      errors: { ...errors },
    }
    formikState.errors[fieldName] = undefined
    console.log("reset " + fieldName + "error in form")

    setFormikState(formikState)
  }

  setFirstPartOfStep0isValid = value =>
    this.setState({ firstPartOfStep0isValid: value })

  render() {
    const { children, isValid, dirty } = this.props
    const {
      step,
      eDealAvailable,
      waiting,
      firstPartOfStep0isValid,
      modeAdhesion,
      classButton,
    } = this.state
    const activeStep = React.Children.toArray(children)[step]

    // const  waiting = true;
    const step1QuotationButtonClassNames = classNames({
      "btn-submit": eDealAvailable,
      "btn-disabled": !eDealAvailable,
    })
    console.log("step", step)
    const quotationFormClasses = classNames("quotation-v2 col-12", {
      "col-md-8 offset-md-2": step !== 1,
    })

    return (
      <div>
        <div
          hidden={step < 0}
          className="col-md-6 offset-md-3 stepper quotation-stepper"
        >
          <MgcStepper
            activeStep={step < 3 ? step : step + 1}
            steps={this.steps}
          />
        </div>
        <div className={quotationFormClasses}>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={this.initialValues}
            validationSchema={QuotationValidation[step]}
            validateOnBlur={false}
            validateOnChange={false}
            render={props => (
              <form onSubmit={props.handleSubmit} className="">
                {React.cloneElement(activeStep, {
                  ...props,
                  formules: this.state.formules,
                  navigateBack: this.navigateBack,
                  offer: this.state.offer,
                  isErrorSubmitting: this.isErrorSubmitting(props.submitCount),
                  setAllSubmitCount: this.setAllSubmitCount,
                  resetErrorsAfterSubmitWithErrors: name =>
                    this.resetErrorsAfterSubmitWithErrors(
                      props.setFormikState,
                      props.errors,
                      name
                    ),
                  onContinue: values =>
                    this.continueQuotation(props.setValues, values),
                  resetErrorsAfterValidateWithErrors: name =>
                    this.resetErrorsAfterValidateWithErrors(
                      props.setFormikState,
                      props.errors,
                      name
                    ),
                  firstPartOfStep0isValid,
                  setFirstPartOfStep0isValid: this.setFirstPartOfStep0isValid,
                })}
                {(step === 0 || step === 2) && waiting && (
                  <Col className="ptop text-center mx-auto">
                    {this.state.waiting && (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    )}
                  </Col>
                )}
                {step === 0 && (
                  <Col className="ptop text-center mt-lg-0">
                    {!this.state.waitingForStep2 &&
                    this.state.firstPartOfStep0isValid ? (
                      <button
                        type="submit"
                        className={` ${
                          waiting
                            ? "btn-disabled ptop mx-auto"
                            : "btn-submit ptop mx-auto"
                        }`}
                        disabled={waiting}
                      >
                        TARIFICATION
                      </button>
                    ) : (
                      ""
                    )}
                  </Col>
                )}
                {step === 1 && (
                  <div>
                    <div className="row">
                      <div className="col-12 col-md-8 offset-md-2">
                        <Row className="quotation-btn-submit-no-margin">
                          
                          
                        {modeAdhesion==="FULL" && (
                          
                          <Col
                            className="ptop"
                            md={5}
                            xs={{ span: 10, offset: 1 }}
                          >
                            <button
                              type="submit"
                              className={step1QuotationButtonClassNames}
                              disabled={!eDealAvailable}
                              // mis ici parce que si c'est mis dans la page de l'étape 3, c'est appelé même si
                              // on clicque sur 'J'Adhère'
                              onClick={() => {
                                ATInternetTagService.sendPage({
                                  page: "etape3_mes_coordonnees_personnelles",
                                  page_chapter1: "mutuelle_sante",
                                  page_chapter2: "devis",
                                })
                              }}


                            >
                              RECEVOIR MON DEVIS
                            </button>
                          </Col>
                        )}

                        {modeAdhesion==="SEND_DEVIS_ONLY" && (
                          <>

                          <Col
                            className="ptop"
                            md={5}
                            xs={{ span: 10, offset: 1 }}
                          >
                            <button
                              type="submit"
                              className={step1QuotationButtonClassNames}
                              disabled={!eDealAvailable}
                              // mis ici parce que si c'est mis dans la page de l'étape 3, c'est appelé même si
                              // on clicque sur 'J'Adhère'
                              onClick={() => {
                                ATInternetTagService.sendPage({
                                  page: "etape3_mes_coordonnees_personnelles",
                                  page_chapter1: "mutuelle_sante",
                                  page_chapter2: "devis",
                                })
                              }}
                            >
                              RECEVOIR MON DEVIS
                            </button>
                          </Col>
                          <Col
                          className="ptop"
                          md={{ span: 5, offset: 0 }}
                          xs={{ span: 10, offset: 1 }}
                          >
                          <button
                            type="button"
                            onClick={() => {
                              this.goToAdhesionPapier()
                            }}
                            className="btn-submit"
                          >
                            ADHERER
                          </button>
                          </Col>
                          
                          </>
                        )}

                        {modeAdhesion==="FULL" && (
                          
                          <Col
                            className="ptop"
                            md={{ span: 5, offset: 0 }}
                            xs={{ span: 10, offset: 1 }}
                          >
                            <button
                              onClick={() => {
                                ATInternetTagService.sendNavClick({
                                  click: "adhesion",
                                })
                                this.goToAel(props.values)
                              }}
                              className="btn-submit"
                            >
                              ADHERER
                            </button>
                          </Col>

                        )}

                        </Row>

                        {(modeAdhesion==="SEND_DEVIS_ONLY") && (
                        <Row>
                         <Col  xs={{ span: 10, offset: 1 }}> 
                        <p>
                        La souscription en ligne est momentanément indisponible pour les garanties proposées. Nous sommes à votre disposition au 01 40 78 57 10.
                        </p>
                        </Col>
                        </Row>
                      )} 

                      {(modeAdhesion==="NOTHING") && (
                        <Row>
                         <Col  xs={{ span: 10, offset: 1 }}> 
                        <p className="gammedisabled">
                        Les garanties correspondant à vos besoins sont momentanément indisponibles en ligne.
                        Nous sommes à votre disposition au 01 40 78 57 10 pour établir votre devis et répondre à vos questions.                        </p>
                        </Col>
                        </Row>
                      )} 


                        <div className="row" hidden={eDealAvailable}>
                          <div className="col">
                            <div id="ael0contentCoche">
                              L'envoi de devis est temporairement indisponible.
                            </div>
                          </div>
                        </div>
                        <div className="quotation-footer-step-2">
                          Vous souhaitez connaître les tarifs de nos autres
                          garanties ?{" "}
                          <a
                            href="https://www.mutuellemgc.fr/contact/"
                            target="_blank"
                          >
                            Appelez-nous !
                          </a>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <>
                    <Col
                      className="ptop quotation-btn-submit-no-margin"
                      xs={12}
                      md={{ span: 6, offset: 3 }}
                    >
                      <button
                        type="submit"
                        className={waiting ? "btn-disabled" : "btn-submit"}
                        disabled={waiting}
                      >
                        RECEVOIR MON DEVIS
                      </button>
                    </Col>
                    <Row>
                      <Col className="quotation-step-3-footer">
                        Vos données personnelles ont été recueillies par la MGC
                        pour l'établissement à votre demande du présent devis
                        personnalisé. Conformément à la loi «Informatique et
                        Libertés» n°78-17 du 6 janvier 1978 modifée, vous
                        bénéfciez d'un droit d'accès, de rectifcation et le cas
                        échéant, d'opposition et de suppression aux informations
                        vous concernant. Ces droits peuvent être exercés en vous
                        adressant à : MGC Service Devis, 2-4 place de l'Abbé
                        Georges Hénocque, 75637 PARIS Cedex 13.
                      </Col>
                    </Row>
                  </>
                )}
                {step === 3 && (
                  <Row className="quotation-btn-submit-no-margin">
                    <Col className="ptop" md={6}>
                      <button
                        type="button"
                        className="btn-submit"
                        onClick={this.navigateBack}
                      >
                        EFFECTUER UN NOUVEAU DEVIS
                      </button>
                    </Col>
                    <Col className="ptop" md={6}>
                      <button
                        type="submit"
                        className="btn-submit"
                        onClick={() =>
                          ATInternetTagService.sendNavClick({
                            click: "adhesion",
                          })
                        }
                      >
                        ADHERER
                      </button>
                    </Col>
                  </Row>
                )}
                {step === 4 && (
                  <Col
                    className="ptop quotation-btn-submit-no-margin"
                    xs={{ span: 8, offset: 2 }}
                    lg={{ span: 6, offset: 3 }}
                  >
                    <button
                      type="button"
                      className="btn-submit ptop"
                      onClick={this.navigateBack}
                    >
                      RETOUR
                    </button>
                  </Col>
                )}
              </form>
            )}
          />
        </div>
        <div
          className={"col-md-8 offset-md-2 col-10 offset-1 mention-quot-wizard"}
        >
          <p>
            Les données à caractère personnel (DCP) collectées sont destinées à
            la Mutuelle Générale des Cheminots (MGC) en sa qualité de
            Responsable de traitement et plus précisément aux services habilités
            de la MGC. Ces DCP et informations font l’objet de traitements
            (automatisé et non automatisé). Les champs marqués d'un astérisque
            (*) sont obligatoires et nécessaires à l’établissement du devis en
            vue de vous proposer un contrat d’assurance cohérent avec vos
            besoins et exigences. À défaut la mutuelle MGC ne sera pas en mesure
            de traiter votre demande. Si vous poursuivez votre navigation en
            cliquant sur « adhérez en ligne », elles serviront aussi à la
            création de votre espace personnel sécurisé « Adhésion en ligne » et
            à préremplir votre bulletin d’adhésion en ligne. Elles peuvent
            également être utilisées pour les finalités décrites dans la Charte
            relative à la protection des données disponible sur notre site&nbsp;
            <a href={"https://www.mutuellemgc.fr"}>www.mutuellemgc.fr</a>.
            <br />
            En application de la réglementation française et européenne, vous
            disposez :
            <ul>
              <li>
                d’un droit d’accès, de rectification, d’effacement et de
                portabilité des données vous concernant ;
              </li>
              <li>
                d’un droit d’opposition et à la limitation du traitement ;
              </li>
              <li>
                du droit de définir des directives générales, qui peuvent être
                enregistrées auprès d’un tiers de confiance numérique certifié
                par la CNIL ou particulières relatives à la conservation, à
                l’effacement et à la communication de vos DCP après votre décès
                et qui peuvent être enregistrées auprès de la MGC. Pour exercer
                l’un des droits susvisés et en savoir plus sur la politique de
                protection des données de la MGC, nous vous invitons à consulter
                les conditions d’adhésion en ligne et la Charte relative à la
                protection des données figurant sur le site internet de la
                MGC&nbsp;
                <a href={"https://www.mutuelleMGC.fr"}>www.mutuelleMGC.fr</a>.
              </li>
            </ul>
            Vous pouvez également contacter le Délégué à la Protection des
            données personnelles de la Mutuelle aux coordonnées suivantes
            :&nbsp;
            <a href={"mailto:service.dpo@m-g-c.com"}>service.dpo@m-g-c.com</a>.
            <br />
            En cas de réclamation sur la gestion de vos données personnelles,
            vous avez également la possibilité de contacter la CNIL.
          </p>
        </div>
      </div>
    )
  }
}

export default QuotationWizardV2
