import { Col, Row } from "react-bootstrap"
import { Field } from "formik"
import React from "react"
import QuotationBesoinV2 from "./quotationBesoinV2"
import PropTypes from "prop-types"
import MgcRadioToggleButtonGroupV2 from "../../global/mgcRadioToggleButtonGroupV2"
import MgcRadioToggleButtonV2 from "../../global/mgcRadioToggleButtonV2"
import "./quotationBesoinsV2.css"

const QuotationBesoinsV2 = ({ errors, touched, questions, setFieldValue }) => {
  // cas particulier pour l question couverture Zen ce n'est pas un booleen
  const zenQuestion = questions.find(q => {
    return q.path === "couvertureZen"
  })
  const filteredQuestions = questions.filter(q => {
    return q.path !== "couvertureZen"
  })
  return (
    <>
      {zenQuestion && (
        <Row className="question-y-n">
          <Col xs={12}>
            <span
              dangerouslySetInnerHTML={{ __html: zenQuestion.labelQuestion }}
            />
          </Col>
          <Col xs={12} className="quotation-radio">
            <MgcRadioToggleButtonGroupV2
              id={zenQuestion.path}
              error={errors[`${zenQuestion.path}`]}
              touched={touched[`${zenQuestion.path}`]}
              withoutOffset={true}
            >
              <Field
                component={MgcRadioToggleButtonV2}
                name={zenQuestion.path}
                id="NIVEAU_PRESTA"
                label={"Niveau de couverture"}
              />
              <Field
                component={MgcRadioToggleButtonV2}
                name={zenQuestion.path}
                id="TARIF"
                label={"Montant de cotisation"}
              />
            </MgcRadioToggleButtonGroupV2>
          </Col>
        </Row>
      )}
      <div hidden={!filteredQuestions || !filteredQuestions.length || filteredQuestions.length <=0} className="besoins-desc">
        <p>Choisissez les postes de dépenses pour
          lesquels vous souhaitez être couvert&#160;:</p>
      </div>
      <div className="row">
        <div className="col-12 col-lg-11">
          <div className="row my-2">
            {filteredQuestions &&
            filteredQuestions
              .filter(q => {
                return q.labelQuestion && q.path && q.path !== "couvertureZen"
              })
              .map((question, idx) => {
                return (
                  <QuotationBesoinV2
                    title={question.labelQuestion}
                    path={question.path}
                    onChange={setFieldValue}
                    key={idx}
                  />
                )
              })}
          </div>
        </div>
      </div>

    </>
  )
}

QuotationBesoinsV2.propTypes = {
  errors: PropTypes.array,
  touched: PropTypes.bool,
  questions: PropTypes.array,
  setFieldValue: PropTypes.func,
}

export default QuotationBesoinsV2
