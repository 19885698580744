import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import QuotationWizard from "../../quotationWizard"
import GAnalyticsService from "../../../services/gAnalyticsService"
import FormulasV2 from "./formulasV2"
import "./quotationStep2V2.css"
import AelOffreV2 from "../../ael/v2/aelOffreV2"
import { ATInternetTagService } from './../../../services/global/ATInternetService';

class QuotationStep2V2 extends React.Component {
  state = {
    formules: [],
    dateAdhesion: "",
    age: "",
    ageConjoint: "",
    regimeAssuranceMal: "",
    regimeAssuranceMalConjoint: "",
  }
  tns
  props
  constructor(props) {
    super(props)
    this.props = props
  }
  stepBack(back, waiting) {
    waiting()
    back()
  }
  componentDidMount() {

    const { values, rA, formules, onContinue, setFieldValue } = this.props

    ATInternetTagService.sendPage({page:'etape2_mes_garanties',page_chapter1:'mutuelle_sante',page_chapter2:'devis'});

    if (sessionStorage.getItem("devisStepOne")) {
      onContinue(JSON.parse(sessionStorage.getItem("devisStepOne")))
    }

    let codeGamme;
    if (formules.length >= 1) {
      codeGamme = formules[0].gamme.code
      "TNS" === codeGamme ? (this.tns = true) : (this.tns = false)
      setFieldValue("codeGamme", codeGamme)
      GAnalyticsService.sendEvent("Devis santé", "Tarification", codeGamme)
    }

    window.dataLayer.push({
      event: "quotation_form",
      form_name: "mutuelle_sante",
      step_id: "step2",
      step_name: "Mes garanties",
      gamme_professionnelle: codeGamme
    });

    if (rA.data) {
      rA.data.map(e =>
        e.id === values.regimeAssuranceMal
          ? this.setState({ regimeAssuranceMal: e.libelle })
          : ""
      )
      if (values.regimeAssuranceMalConjoint) {
        rA.data.map(e =>
          e.id === values.regimeAssuranceMalConjoint
            ? this.setState({ regimeAssuranceMalConjoint: e.libelle })
            : ""
        )
      }
    }
    this.setState({ age: calculateAge(values.dateNaissance) })
    if (values.dateNaissanceConjoint) {
      this.setState({ ageConjoint: calculateAge(values.dateNaissanceConjoint) })
    }
    let date = new Date(Date.parse(values.debutContrat))
    let options = { day: "numeric", year: "numeric", month: "numeric" }
    this.setState({ dateAdhesion: date.toLocaleDateString("fr-FR", options) })
    window.scrollTo(0, 0)
    console.log('declaration de page pour quotationStep2V2')
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submitCount !== this.props.submitCount) {
      this.props.setAllSubmitCount(this.props.submitCount)
    }
  }

  render() {
    const { formules, navigateBack, values, offer, waiting } = this.props
    return (
      <QuotationWizard.Step>
        <Container className="quotation container-step-2">
          <div className="row">
            <div className="col-12 col-md-9 offset-md-2 offset-xl-2">
              <div className="quotation-step-2-header-with-button">
                <div>
                  <h2>RAPPEL DE VOTRE SITUATION</h2>
                </div>
                <div className="quotation-btn">
                  <button
                    type="button"
                    className="btn-quotation-step2"
                    onClick={() => this.stepBack(navigateBack, waiting)}
                  >
                    Modifier
                  </button>
                </div>
              </div>
              <Row className="quotation-step2-pres">
                <Col xs={12}>
              <span>
                Un bénéficiaire de {this.state.age} ans,{" "}
                {this.state.regimeAssuranceMal}
              </span>
                </Col>
                {values.conjointChecked ? (
                  <Col xs={12}>
                <span>
                  Conjoint de {this.state.ageConjoint} ans,{" "}
                  {this.state.regimeAssuranceMalConjoint}
                </span>
                  </Col>
                ) : (
                  ""
                )}
                {values.nbEnfantsPlus1an ? (
                  <Col xs={12}>
                <span>
                  {values.nbEnfantsPlus1an} enfant(s) de plus d'un an.
                </span>
                  </Col>
                ) : (
                  ""
                )}
                {values.nbEnfantsMoisn1an ? (
                  <Col xs={12}>
                <span>
                  {values.nbEnfantsMoisn1an} enfant(s) de moins d'un an.
                </span>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={12}>
              <span>
                {" "}
                Date d'adhésion souhaitée : {this.state.dateAdhesion}{" "}
              </span>
                </Col>
              </Row>
              <AelOffreV2 offres={offer.data} />

              <div className="row">
                <div className="col-12 col-xl-10">
                  <h2 className="text-uppercase">Choix des garanties</h2>
                  <p className="choix-garanties">Pour faire défiler les différents niveaux de formules et leurs garanties, cliquez sur les flèches.</p>
                </div>
              </div>

            </div>
          </div>

          <Row>
            <Col xs={12} lg={{offset: 2, span: 8}} xl={{span: 12, offset: 0}}>
              {formules.length >= 1 ? (
                <FormulasV2
                  formules={formules}
                  button={false}
                  tns={this.tns}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}
export default QuotationStep2V2

function calculateAge(birthday) {
  birthday = Date.parse(birthday)
  var ageDifMs = Date.now() - birthday
  var ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
