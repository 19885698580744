import React from "react"
import { Col, Row } from "react-bootstrap"
import { Field } from "formik"
import MgcCpVilleV2 from "../../global/mgcCpVilleV2"
import MgcRadioToggleButtonGroupV2 from "../../global/mgcRadioToggleButtonGroupV2"
import MgcRadioToggleButtonV2 from "../../global/mgcRadioToggleButtonV2"

const QuotationTNSV2 = ({
  errors,
  dirty,
  setFieldValue,
  values,
  touched,
  notOffset,
  landing,
  isErrorSubmitting,
  resetErrorsAfterSubmitWithErrors,
  handleChangeWithReset,
  showErrorOnValidation
}) => (
  <div>
    <MgcCpVilleV2
      nameCP="codePostalTNS"
      labelCp="Code postal *"
      labelVille="Ville *"
      nameVille="villeTNS"
      notOffset={notOffset}
      errors={errors}
      dirty={dirty}
      setFieldValue={setFieldValue}
      values={values}
      placeholderCp="Ex : 75001"
      withoutOffset={true}
      handleChangeWithReset={handleChangeWithReset}
      isErrorSubmitting={isErrorSubmitting}
      resetErrorsAfterSubmitWithErrors={resetErrorsAfterSubmitWithErrors}
      showErrorOnValidation
    />
    <Row>
      <Col xs={12} className={landing && "landing-agentTerritoral"}>
        <div className="form-row">
          <label className="col-12">Entreprise (facultatif)</label>
          <div className="form-group col-11">
            <Field
              type="text"
              name="nomEntrepriseTNS"
              className="form-control"
              placeholder="Ex : SARL TEST"
              onChange={(e) => {
                handleChangeWithReset(e)
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
    <div className="col-12" className="quotation-radio">
      <MgcRadioToggleButtonGroupV2
        label="Avez-vous des salariés ? *"
        id="salaries"
        error={errors.salariesTNS}
        touched={touched.salariesTNS}
        withoutOffset={true}
        isErrorSubmitting={isErrorSubmitting}
      >
        <Field
          component={MgcRadioToggleButtonV2}
          name="salariesTNS"
          id="true"
          label={"Oui"}
          onChangeWithReset={handleChangeWithReset}
        />
        <Field
          component={MgcRadioToggleButtonV2}
          name="salariesTNS"
          id="false"
          label={"Non"}
          onChangeWithReset={handleChangeWithReset}
        />
      </MgcRadioToggleButtonGroupV2>
    </div>
  </div>
)

export default QuotationTNSV2
