import React from "react"
import OffresPromosService from "../../services/offresPromosService"


class QuotationPromos extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      titre: "",
      validite: ""
    }
  }

  componentDidMount() {
    OffresPromosService.getOffresPromos().then(response => {
      const promotions = response.data;
      this.setState({
        titre: promotions.titre.trim(),
        validite: promotions.validite.trim()
      });
    });
  }

  render() {
    const hasTitle = (this.state.titre !== null && this.state.titre !== "");
    const hasValidite = (this.state.validite !== null && this.state.validite !== "");
    const shouldDisplay = hasTitle && hasValidite;

    return shouldDisplay
      ? <div className="header-quotation">
        <div className="subheader-quotation">
          <h4>{this.state.titre}</h4>
          {this.state.validite}
        </div>
        <div>
          <a href="/mutuelle-sante/offres-promotionnelles/">
            Voir les conditions de l'offre
          </a>
        </div>
      </div>
      : null
  }
}

export default QuotationPromos;