import React from "react"
import QuotationWizard from "./quotationWizard"
import Alert from 'react-bootstrap/Alert'
class QuotationGDAALert extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <QuotationWizard.Step>
        <div className="quotation-alert">
          <Alert variant="danger">
            <p>
              Votre demande est en cours de traitement par nos services. Nous reviendrons vers vous dans les plus brefs délais.
            </p>
          </Alert>
        </div>
      </QuotationWizard.Step>
    )
  }
}
export default QuotationGDAALert