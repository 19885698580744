import React from "react"
import { Col, Row } from "react-bootstrap"
import MgcSelectFieldV2 from "../../global/mgcSelectFieldV2"
import MgcNewDateFieldV2 from "../../global/mgcNewDateFieldV2"

const QuotationConjointV2 = ({
  tab1,
  tab2,
  errors,
  dirty,
  values,
  setFieldValue,
  isErrorSubmitting,
  resetErrorsAfterSubmitWithErrors,
  showErrorOnValidation,
  tooltipTitlesituationProConjoint,
  tooltipsituationProConjoint,
  tooltipOnsituationProConjoint
}) => (
  <div>
    <Row>
      <Col xs={12}>
        <MgcNewDateFieldV2
          dirty={dirty}
          errors={errors}
          name="dateNaissanceConjoint"
          className="form-control"
          withoutOffset={false}
          label={"Date de naissance *"}
          setFieldValue={setFieldValue}
          values={values}
          isErrorSubmitting={isErrorSubmitting}
          resetErrorsAfterSubmitWithErrors={resetErrorsAfterSubmitWithErrors}
          showErrorOnValidation
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12} className={"regimeAss"}>
        <MgcSelectFieldV2
          dirty={dirty}
          name="regimeAssuranceMalConjoint"
          errors={errors}
          label="Régime de sécurité sociale *"
          firstOption="- Sélectionner le régime -"
          className={"form-control"}
          setFieldValue={setFieldValue}
          onChange={() =>
            resetErrorsAfterSubmitWithErrors("regimeAssuranceMalConjoint")
          }
          isErrorSubmitting={isErrorSubmitting}
          table={tab1}
          showErrorOnValidation
          withOffset={true}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <MgcSelectFieldV2
          dirty={dirty}
          assusranceMalUnselected={!values.regimeAssuranceMalConjoint}
          name="situationProConjoint"
          errors={errors}
          label="Situation professionnelle *"
          firstOption="- Choisir la situation pro. -"
          className="form-control"
          setFieldValue={setFieldValue}
          onChange={() =>
            resetErrorsAfterSubmitWithErrors("situationProConjoint")
          }
          isErrorSubmitting={isErrorSubmitting}
          table={
            tab2[values.regimeAssuranceMalConjoint]
              ? tab2[values.regimeAssuranceMalConjoint]
              : []
          }
          showErrorOnValidation = {showErrorOnValidation}
          tooltipTitle = {tooltipTitlesituationProConjoint}
          tooltip = {tooltipsituationProConjoint}
          tooltipOn = {tooltipOnsituationProConjoint}
          withOffset = {true}
        />
      </Col>
    </Row>
  </div>
)

export default QuotationConjointV2
