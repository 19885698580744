import React from "react"
import { Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import QuotationWizard from "../../quotationWizard"
import QuotationService from "../../../services/quotationService"
import QuotationMapper from "../../../services/quotationMapper"
import QuotationBesoinsV2 from "./quotationsBesoinsV2"
import MgcSelectFieldV2 from "../../global/mgcSelectFieldV2"
import QuotationConjointV2 from "./quotationConjointV2"
import QuotationAGCOLTERV2 from "./quotationAGCOLTERV2"
import QuotationTNSV2 from "./quotationTNSV2"
import MgcNewDateFieldV2 from "../../global/mgcNewDateFieldV2"
import QuotationChildrenV2 from "./quotationChildrenV2"
import QuotationChildrenTNSV2 from "./quotationChildrenTNSV2"
import "./quotationStep1V2.css"
import { scrollToFirstError } from "../../../services/global/formErrorsService"
import MgcPhoneFieldV2 from "../../mgcPhoneFieldV2"
import { ATInternetTagService } from "./../../../services/global/ATInternetService"
import moment from "moment"
import MgcDateField from "../../mgcDateField"

class QuotationStep1V2 extends React.Component {
  sProFilter
  situationPro
  dateEffet
  besoinTNS
  values = this.props.values
  initialValues = this.props.initialValues
  state = {
    rA: this.props.rA,
    sP: this.props.sP,
    datesEffetMinMax: null,
    getDate: false,
    tns: false,
    agentTerritorial: false,
    questions: [],
    initRegimeAssuranceMal: "",
    gammeDisabledAdhesion:false,
  }
  tabEnfantplus1An = [
    { id: 1, libelle: "1 enfant" },
    { id: 2, libelle: "2 enfants et plus" },
  ]
  // errorRef = React.createRef()

  constructor(props) {
    super(props)
    this.errorRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.rA !== prevProps.rA &&
      !sessionStorage.getItem("regimeAss")
    ) {
      let regime = []
      this.props.rA
        ? this.props.rA.data.map(e => {
            e.id = e.code
            regime.push(e)
          })
        : regime.push({})
      this.setState({ rA: regime })
    }
    if (
      this.props.sP !== prevProps.sP &&
      !sessionStorage.getItem("situationPro")
    ) {
      this.setState({ sP: this.props.sP })
    }
    if (prevProps.submitCount !== this.props.submitCount) {
      this.props.setAllSubmitCount(this.props.submitCount)
    }
    if (
      typeof window !== "undefined" &&
      !this.props.isValid &&
      !this.props.isValidating &&
      prevProps.isSubmitting !== this.props.isSubmitting
    ) {
      const { errors } = this.props
      scrollToFirstError(errors)
    }
    // check if first part of form is valid
    if (
      prevProps.isValidating &&
      this.props.isValidating === false &&
      !this.props.firstPartOfStep0isValid &&
      !this.props.errors.dateNaissance &&
      !this.props.errors.regimeAssuranceMal &&
      !this.props.errors.situationPro &&
      !this.props.errors.employeurAgentTerritotrial &&
      !this.props.errors.cpEmployeurAngentTerritotrial &&
      !this.props.errors.dejaCouvert &&
      !this.props.errors.dateFinDernierContrat &&
      !this.props.errors.tauxMajorationDernierContrat &&
      !this.props.errors.dateEntreeFonctionPublique &&
      !this.props.errors.codePostalTNS &&
      !this.props.errors.nomEntrepriseTNS &&
      !this.props.errors.salariesTNS &&
      !this.props.errors.dateNaissanceConjoint &&
      !this.props.errors.regimeAssuranceMalConjoint &&
      !this.props.errors.situationProConjoint &&
      !this.props.errors.childrenChecked &&
      !this.props.errors.nbEnfantsMoisn1an &&
      !this.props.errors.nbEnfantsPlus1an &&
      !this.props.errors.regimeAssuranceMalEnfant1 &&
      !this.props.errors.regimeAssuranceMalEnfant2 &&
      !this.props.errors.debutContrat
    ) {
      this.getQuestions()
    }
  }

  checkTNS(val) {
    const { setFieldValue } = this.props
    if (this.state.sP[val.regimeAssuranceMal]) {
      let tns = this.state.sP[val.regimeAssuranceMal].filter(
        e => e.id === val.situationPro
      )[0]
        ? this.state.sP[val.regimeAssuranceMal].filter(
            e => e.id === val.situationPro
          )[0].tns
        : false
      this.setState({ tns: tns })
      setFieldValue("tns", tns)
      val.tns = tns
      if (this.tns !== true) {
        let agentTerritorial = this.state.sP[val.regimeAssuranceMal].filter(
          e => e.id === val.situationPro
        )[0]
          ? this.state.sP[val.regimeAssuranceMal].filter(
              e => e.id === val.situationPro
            )[0].agentTerritorial
          : false
        this.setState({ agentTerritorial: agentTerritorial })
        setFieldValue("agentTerritorial", agentTerritorial)
        val.agentTerritorial = agentTerritorial
      }
    }
  }

  handleRegimeAssuranceMalChange = value => {
    const {
      setFieldValue,
      values,
      resetErrorsAfterSubmitWithErrors,
    } = this.props
    setFieldValue("regimeAssuranceMal", value)
    setFieldValue("situationPro", "")
    resetErrorsAfterSubmitWithErrors("regimeAssuranceMal")
    this.setState({ tns: false, agentTerritorial: false })
    this.getDatesEffetMinMax(values.dateNaissance, value, values.situationPro)
  }

  handleChildrenCheckedlChange() {
    const { values } = this.props
    this.getDatesEffetMinMax(
      values.dateNaissance,
      values.regimeAssuranceMal,
      values.situationPro
    )
  }

  handleSituationProChange = value => {
    const {
      setFieldValue,
      values,
      resetErrorsAfterSubmitWithErrors,
    } = this.props
    values.situationPro = value
    setFieldValue("situationPro", value)
    resetErrorsAfterSubmitWithErrors("situationPro")
    this.checkTNS(values)
    this.getDatesEffetMinMax(
      values.dateNaissance,
      values.regimeAssuranceMal,
      value
    )
  }

  handleDateNaissanceChange = value => {
    const { setFieldValue, values } = this.props
    setFieldValue("dateNaissance", value)
    this.getDatesEffetMinMax(
      value,
      values.regimeAssuranceMal,
      values.situationPro
    )
  }

  questionsLoaded = questions => {
    return questions && questions.length > 0
  }

  questionsSeniorDepassementModere = questions => {
    if(questions && questions.length > 0){
      const filteredQuestions = questions.filter(q => {
        return q.path === "depassementModere"
      })
      if(filteredQuestions && filteredQuestions.length>0){
        return true
      }
    }
    return false
  }

  questionsSeniorRacZero = questions => {
    if(questions && questions.length > 0){
      const filteredQuestions = questions.filter(q => {
        return q.path === "racZero"
      })
      if(filteredQuestions && filteredQuestions.length>0){
        return true
      }
    }
    return false
  }

  componentDidMount() {
    const { onContinue, values } = this.props

    ATInternetTagService.sendPage({
      page: "etape1_ma_situation",
      page_chapter1: "mutuelle_sante",
      page_chapter2: "devis",
    })

    window.dataLayer.push({
      event: "quotation_form",
      form_name: "mutuelle_sante",
      step_id: "step1",
      step_name: "Ma situation",
    });


    if (
      values.regimeAssuranceMal &&
      values.situationPro &&
      values.dateNaissance
    ) {
      this.checkTNS(values)
    }

    if (values.debutContrat) {
      this.getDatesEffetMinMax(
        values.dateNaissance,
        values.regimeAssuranceMal,
        values.situationPro
      )
    }

    if (sessionStorage.getItem("devisStepOne")) {
      onContinue(JSON.parse(sessionStorage.getItem("devisStepOne")))
    }
    if (sessionStorage.getItem("regimeAss")) {
      this.setState({ rA: JSON.parse(sessionStorage.getItem("regimeAss")) })
    }
    if (sessionStorage.getItem("situationPro")) {
      this.setState({ sP: JSON.parse(sessionStorage.getItem("situationPro")) })
    }
    // validateForm()
    if (this.state.rA.headers && !sessionStorage.getItem("regimeAss")) {
      this.setState({ rA: this.state.rA.data })
    }
    // this.errorRef = React.createRef()
    console.log("declaration de page pour quotationStep1V2")
  }

  handleChangeWithReset = e => {
    const { handleChange, resetErrorsAfterValidateWithErrors } = this.props
    handleChange(e)
    resetErrorsAfterValidateWithErrors(e.target.name)
  }

  setFieldValueWithReset = (name, value) => {
    this.props.setFieldValue(name, value)
    this.props.resetErrorsAfterValidateWithErrors(name)
  }

  getDatesEffetMinMax = (dateNaissance, regimeAssuranceMal, situationPro) => {
    const { values, setFieldValue } = this.props

    if (dateNaissance && regimeAssuranceMal && situationPro) {
      QuotationService.getDatesMinMax(
        dateNaissance,
        regimeAssuranceMal,
        situationPro,
        values.childrenChecked
      ).then(
        result => {
          const datesEffetMinMax = result.data
          this.setState({ datesEffetMinMax })

          if (
            values.debutContrat &&
            (new Date(datesEffetMinMax.dateMin).getTime() >
              new Date(values.debutContrat).getTime() ||
              new Date(datesEffetMinMax.dateMax).getTime() <
                new Date(values.debutContrat).getTime())
          ) {
            setFieldValue("debutContrat", null)
            this.resetQuestions()
          } else if (!values.debutContrat) {
            setFieldValue(
              "debutContrat",
              moment(datesEffetMinMax.dateMin).date() === 1
                ? datesEffetMinMax.dateMin
                : moment(datesEffetMinMax.dateMin)
                    .add(1, "M")
                    .date(1)
                    .format("YYYY-MM-DD")
            )
          }
        },
        reason => console.error(reason)
      )
    }
  }

  resetQuestions = () => {
    this.setState({ questions: [] })
  }

  validateFirstPartForm = () => {
    this.resetQuestions()
    this.props.validateForm()
  }

  getQuestions = () => {
    this.props.resetErrorsAfterSubmitWithErrors("debutContrat")
    const devis = QuotationMapper.toDevisDTO(
      this.props.values,
      this.props.values.dateEffet
    )
    QuotationService.getDDAQuestions(devis, this.props.values.dateEffet).then(
      r => {

        if(r.data.length>0 && r.data[0] === 'GAMME_DISABLE') {
          this.setState({
            gammeDisabledAdhesion:true,
          })
        }else{
          this.setState({
            gammeDisabledAdhesion:false,
          })          
        }
        this.setState({ questions: r.data })
        if (
          !!r.data.find(question => {
            return question.path === "couvertureZen"
          })
        ) {
          this.props.setFieldValue("zen", true)
        }

        if(this.state.gammeDisabledAdhesion){
          this.props.setFirstPartOfStep0isValid(false)
        }else{
          this.props.setFirstPartOfStep0isValid(true)
        }

      }
    )
  }

  checkFirstPartForm = () => {
    if (this.props.firstPartOfStep0isValid) {
      this.resetQuestions()
      this.props.setFirstPartOfStep0isValid(false)
    }
  }

  handleChangeWithImpactOnDateEffet = (name, value) => {
    if (this.props.errors[name]) {
      this.props.resetErrorsAfterValidateWithErrors(name)
    }
    switch (name) {
      case "dateNaissance":
        this.handleDateNaissanceChange(value)
        break
      case "regimeAssuranceMal":
        this.handleRegimeAssuranceMalChange(value)
        break
      case "situationPro":
        this.handleSituationProChange(value)
        break
      case "childrenChecked":
        this.handleChildrenCheckedlChange()
        break
      default:
        break
    }
    this.checkFirstPartForm()
  }

  render() {
    const {
      errors,
      dirty,
      values,
      touched,
      setFieldValue,
      isErrorSubmitting,
      resetErrorsAfterSubmitWithErrors,
      isValidating,
    } = this.props
    this.situationPro = this.props.sP
    const {
      gammeDisabledAdhesion,
    } = this.state





    return (
      <QuotationWizard.Step>
        <Container className="quotation">

          <div className="title-btn-rollback-ael form-row mb-2">

          <div className="col-1 text-right">
          </div>
          <div className="title-rollback-ael">
            <h2 ref={this.errorRef}>PERSONNE(S) À COUVRIR</h2>
            <h3>Adhérent principal</h3>
          </div>
          </div>


          <Row>
            <Col xs={12}>
              <MgcNewDateFieldV2
                dirty={dirty}
                errors={errors}
                name="dateNaissance"
                className="form-control form-row-step-2"
                withoutOffset={false}
                label={"Date de naissance *"}
                setFieldValue={setFieldValue}
                values={values}
                onChange={e =>
                  this.handleChangeWithImpactOnDateEffet("dateNaissance", e)
                }
                touched={touched}
                isErrorSubmitting={isErrorSubmitting}
                resetErrorsAfterSubmitWithErrors={
                  resetErrorsAfterSubmitWithErrors
                }
                showErrorOnValidation
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MgcSelectFieldV2
                withOffset={true}
                dirty={dirty}
                name="regimeAssuranceMal"
                label="Régime de sécurité sociale *"
                errors={errors}
                firstOption="- Sélectionner votre régime -"
                className="form-control form-row-step-2"
                onChange={e =>
                  this.handleChangeWithImpactOnDateEffet(
                    "regimeAssuranceMal",
                    e
                  )
                }
                isErrorSubmitting={isErrorSubmitting}
                table={this.state.rA.data ? this.state.rA.data : this.state.rA}
                setFieldValue={setFieldValue}
                showErrorOnValidation
              />
            </Col>
          </Row>

              <MgcSelectFieldV2
                label="Situation professionnelle *"
                dirty={dirty}
                name="situationPro"
                errors={errors}
                withOffset={true}
                firstOption="- Choisir ma situation pro. -"
                className="form-control form-row-step-2"
                setFieldValue={setFieldValue}
                assusranceMalUnselected={!values.regimeAssuranceMal}
                onChange={e =>
                  this.handleChangeWithImpactOnDateEffet("situationPro", e)
                }
                isErrorSubmitting={isErrorSubmitting}
                table={
                  this.state.sP[values.regimeAssuranceMal]
                    ? this.state.sP[values.regimeAssuranceMal]
                    : [{ id: 999, libelle: "" }]
                }
                showErrorOnValidation
                tooltipTitle="Vous êtes auto-entrepreneur ?"
                tooltip="Attention, vous ne pourrez pas bénéficier des avantages fiscaux et sociaux de la loi Madelin. 
                Appelez nos conseillers au 01 40 78 57 10 pour en savoir plus."
                tooltipOn={this.props.values.situationPro==="TNS"}
              />

          <Row>
            {this.state.agentTerritorial && (
              <>
              <Col xs={1}></Col>
              <Col xs={11}>
                <QuotationAGCOLTERV2
                  errors={errors}
                  dirty={dirty}
                  values={values}
                  touched={touched}
                  setFieldValue={this.setFieldValueWithReset}
                  isErrorSubmitting={isErrorSubmitting}
                  resetErrorsAfterSubmitWithErrors={
                    resetErrorsAfterSubmitWithErrors
                  }
                  handleChange={this.handleChangeWithReset}
                  showErrorOnValidation
                />
              </Col>
              </>
            )}
            {this.state.tns && (
              <>
              <Col xs={1}></Col>
              <Col xs={11}>
                <QuotationTNSV2
                  errors={errors}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                  values={values}
                  touched={touched}
                  notOffset={true}
                  isErrorSubmitting={isErrorSubmitting}
                  resetErrorsAfterSubmitWithErrors={
                    resetErrorsAfterSubmitWithErrors
                  }
                  handleChangeWithReset={this.handleChangeWithReset}
                  showErrorOnValidation
                />
              </Col>
              </>
            )}
          </Row>

          
          <Row>
          <Col xs={1}></Col>
              <Col xs={11}>
              <h3>Autre(s) bénéficiaire(s)</h3>
              <Field
                className="check "
                name="conjointChecked"
                type="checkbox"
                component="input"
                id="conjoint"
                checked={values.conjointChecked}
              />
              <label for="conjoint">Mon conjoint</label>
            </Col>      
            </Row>         
            <Row>
            <Col xs={12}>    
          {values.conjointChecked && (
            <QuotationConjointV2
              dirty={dirty}
              errors={errors}
              tab1={this.state.rA.data ? this.state.rA.data : this.state.rA}
              tab2={this.state.sP}
              values={values}
              setFieldValue={this.setFieldValueWithReset}
              isErrorSubmitting={isErrorSubmitting}
              resetErrorsAfterSubmitWithErrors={
                resetErrorsAfterSubmitWithErrors
              }
              handleChangeWithReset={this.handleChangeWithReset}
              showErrorOnValidation
              tooltipTitlesituationProConjoint="Vous êtes auto-entrepreneur ?"
              tooltipsituationProConjoint="Attention, vous ne pourrez pas bénéficier des avantages fiscaux et sociaux de la loi Madelin. 
                        Appelez nos conseillers au 01 40 78 57 10 pour en savoir plus."
              tooltipOnsituationProConjoint={this.props.values.situationProConjoint==="TNS"}
            />
          )}
            </Col>
          </Row>
        
          <Row>
          <Col xs={1}></Col>
              <Col xs={11}>
              <Field
                className="check"
                name="childrenChecked"
                type="checkbox"
                component="input"
                id="children"
                onClick={() =>
                  this.handleChangeWithImpactOnDateEffet(
                    "childrenChecked",
                    !values.childrenChecked
                  )
                }
                checked={values.childrenChecked}
              />
              <label for="children">Mes enfants</label>

          {values.childrenChecked &&
            (!this.state.tns ? (
              <QuotationChildrenV2
                tab={this.tabEnfantplus1An}
                tabRa={this.state.rA.data ? this.state.rA.data : this.state.rA}
                values={values}
                dirty={dirty}
                errors={errors}
                setFieldValue={setFieldValue}
                isErrorSubmitting={isErrorSubmitting}
                resetErrorsAfterSubmitWithErrors={
                  resetErrorsAfterSubmitWithErrors
                }
                handleChangeWithReset={this.handleChangeWithReset}
              />
            ) : (
              <QuotationChildrenTNSV2
                tab={this.tabEnfantplus1An}
                tabRa={this.state.rA.data ? this.state.rA.data : this.state.rA}
                values={values}
                dirty={dirty}
                errors={errors}
                setFieldValue={setFieldValue}
                isErrorSubmitting={isErrorSubmitting}
                resetErrorsAfterSubmitWithErrors={
                  resetErrorsAfterSubmitWithErrors
                }
                handleChangeWithReset={this.handleChangeWithReset}
              />
            ))}

            </Col>
          </Row>
          <Row>
          <Col xs={1}></Col>
            <Col xs={11}>
            <h2 className="p-top-50">DATE DE PRISE D'EFFET DU CONTRAT</h2>
              <div className="form-row">
                <label className="col-11">
                  Date d'effet des garanties souhaitée le: *
                </label>
                <div className="col-9 col-md-7">
                  <MgcDateField
                    name="debutContrat"
                    currentDate={values.debutContrat}
                    isValidating={isValidating}
                    minDate={
                      this.state.datesEffetMinMax &&
                      this.state.datesEffetMinMax.dateMin
                    }
                    maxDate={
                      this.state.datesEffetMinMax &&
                      this.state.datesEffetMinMax.dateMax
                    }
                    placeholder="JJ  /  MM  /  AAAA"
                    withoutOffset="false"
                    isErrorSubmitting={isErrorSubmitting}
                    setFieldValue={(name, value) => {
                      this.checkFirstPartForm()
                      setFieldValue(name, value)
                    }}
                    error={errors.debutContrat}
                    dirty={dirty}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
          <Col xs={1}></Col>
            <Col xs={11}>

          <div className="homeButtonsFlex">
            <button
              type="button"
              className={`${
                this.props.firstPartOfStep0isValid
                  ? "btn-disabled"
                  : "btn-submit"
              } ptop mx-auto`}
              disabled={this.state.firstPartOfStep0isValid}
              onClick={this.validateFirstPartForm}
            >
              Définir mes besoins
            </button>
          </div>

            {gammeDisabledAdhesion && (
          <p className="gammedisabled">
              Les garanties correspondant à vos besoins sont momentanément indisponibles en ligne.
              Nous sommes à votre disposition au 01 40 78 57 10 pour établir votre devis et répondre à vos questions.
          </p>
              )
            }   

           { !gammeDisabledAdhesion && (
             <>
          <h2 hidden={!this.questionsLoaded(this.state.questions)}>BESOINS</h2>
          <h3 hidden={!this.questionsLoaded(this.state.questions)}>
            Pour vous et votre famille
          </h3>

          <QuotationBesoinsV2
            errors={errors}
            touched={touched}
            questions={this.state.questions}
            setFieldValue={setFieldValue}
          />

          <h3 hidden={!this.state.tns && !this.state.agentTerritorial}>
            Pour réaliser un devoir de conseil avisé, un conseiller MGC peut me
            joindre au :
          </h3>

          

          <MgcPhoneFieldV2
            dirty={dirty}
            errors={errors}
            name="telephone"
            label="Téléphone *"
            placeholder="Ex : 0606060606"
            hidden={!this.state.tns && !this.state.agentTerritorial}
            withoutOffset={true}
            isErrorSubmitting={isErrorSubmitting}
            setFieldValue={setFieldValue}
            values={values}
          />
 
        



          <Row>
            <Col md={11} className="text-end-quotation-step1">
              <span>
              <p hidden={!this.questionsSeniorDepassementModere(this.state.questions)}>
              (1) Consultation de professionnels de santé pratiquants des honoraires avec dépassements maitrisés dans le cadre conventionnel de 
               l’Option Pratique Tarifaire Maîtrisée (OPTAM) et l’Option Pratique Tarifaire Maîtrisée Chirurgie et Obstétrique (OPTAM-CO).
              </p>  
              <p hidden={!this.questionsSeniorRacZero(this.state.questions)}>              
              (2) Equipements intégralement pris en charge dans le cadre du dispositif « 100% santé ». Il s’agit d’une sélection de lunettes, 
                  prothèses dentaires et appareils auditifs à tarifs encadrés dont l'intégralité des frais vous sera remboursée par votre caisse 
                  d’Assurance Maladie Obligatoire et votre complémentaire santé.
              </p>
              <p>
                Si vous n’avez pas pu exprimer l’ensemble de vos besoins via ce
                questionnaire, nous vous invitons à contacter l’un de nos
                conseillers mutualistes. Il pourra prendre en compte vos besoins
                et exigences pour vous apporter un conseil avisé. Nos
                conseillers reçoivent une rémunération portant une part fixe et
                une part variable n'excédant pas 15% de leur rémunération et
                n'étant pas basée sur le type de produit vendu.
              </p>

              </span>
            </Col>
          </Row>
            </>
           )
           }

          </Col>
          </Row>

           

        </Container>
      </QuotationWizard.Step>
    )
  }
}

export default QuotationStep1V2
