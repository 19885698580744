import * as React from "react"
import Axios from "axios"
import QuotationALert from "./quotationAlert"
import Spinner from "react-bootstrap/Spinner"
import { MgcgestionTokenService } from "../services/mgcgestionTokenService"
import QuotationWizardV2 from "./quotation/v2/quotationWizardV2"
import QuotationStep1V2 from "./quotation/v2/quotationStep1V2"
import QuotationStep2V2 from "./quotation/v2/quotationStep2V2"
import QuotationStep3V2 from "./quotation/v2/quotationStep3V2"
import QuotationStep4V2 from "./quotation/v2/quotationStep4V2"
import QuotationGDAALert from "./QuotationGDAALert"

class QuotationV2 extends React.Component {
  componentDidMount() {
    if (sessionStorage.getItem("devisStepOne")) {
      this.setState({ waiting: true })
      this.setState({
        initValues: JSON.parse(sessionStorage.getItem("devisStepOne")),
      })
    }
    let URL = `${process.env.GATSBY_MGCGESTION_API_URL}/public/regime-assurances/actif`
    /*        URL ="http://localhost:8080/api/public/regime-assurances/actif"*/
    Axios.get(URL)
      .then(result => {
        this.setState({ regimeAssurance: result })
      })
      .then(() => {
        URL = `${
          process.env.GATSBY_MGCGESTION_API_URL
        }/public/situation-professionnelles/actives`
        /*                URL= "http://localhost:8080/api/public/situation-professionnelles/actives"*/
        Axios.get(URL).then(
          result => {
            let situationPro = []
            for (let o = 0; o < this.state.regimeAssurance.data.length; o++) {
              situationPro[this.state.regimeAssurance.data[o].code] = []
              for (let i = 0; i < result.data.length; i++) {
                for (
                  let j = 0;
                  j < result.data[i].regimeAssurances.length;
                  j++
                ) {
                  if (
                    result.data[i].regimeAssurances[j].code ==
                    this.state.regimeAssurance.data[o].id
                  ) {
                    result.data[i].id = result.data[i].code
                    situationPro[this.state.regimeAssurance.data[o].code].push(
                      result.data[i]
                    )
                  }
                }
              }
            }
            this.setState({ situationProfessionelle: situationPro })
          }
        )
      })
  }
  constructor(props) {
    super(props)
    this.state = {
      regimeAssurance: [1, 2],
      situationProfessionelle: [1, 2],
      waiting: false,
      initValues: {},
    }
  }
  stepBack = () => {
    this.setState({ waiting: false })
  }
  render() {
    return (
      <QuotationWizardV2
        initialValues={this.state.initValues}
        thisStep={this.props.thisStep}
      >
        {this.state.waiting ? (
          <div className={"spinner-step-one"}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <QuotationStep1V2
            rA={this.state.regimeAssurance}
            sP={this.state.situationProfessionelle}
          />
        )}
        <QuotationStep2V2
          rA={this.state.regimeAssurance}
          waiting={this.stepBack}
        />
        <QuotationStep3V2 />
        <QuotationStep4V2 />
        <QuotationALert />
        <QuotationGDAALert />
      </QuotationWizardV2>
    )
  }
}
export default QuotationV2
