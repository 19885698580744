import React from "react"
import { Field } from "formik"
import { Container, Row, Col } from "react-bootstrap"
import QuotationWizard from "../../quotationWizard"
import QuotationEmailsV2 from "./quotationEmailsV2"
import QuotationAdresseV2 from "./quotationAdresseV2"
import MgcTextFieldV2 from "../../global/mgcTextFieldV2"
import MgcRadioToggleButtonGroupV2 from "../../global/mgcRadioToggleButtonGroupV2"
import MgcRadioToggleButtonV2 from "../../global/mgcRadioToggleButtonV2"
import classNames from "classnames"
import { scrollToFirstError } from "../../../services/global/formErrorsService"
import { hasNumber } from "../../../utils/regexTests"
import MgcPhoneFieldV2 from "../../mgcPhoneFieldV2"
import { ATInternetTagService } from "./../../../services/global/ATInternetService"
import GAnalyticsService from "../../../services/gAnalyticsService";

class QuotationStep3V2 extends React.Component {
  constructor(props) {
    super(props)
    this.handleChangeWithReset = this.handleChangeWithReset.bind(this)
  }

  componentDidMount() {
    const { validateForm, formules } = this.props

    let codeGamme;
    if (formules.length >= 1) {
      codeGamme = formules[0].gamme.code
    }

    window.dataLayer.push({
      event: "quotation_form",
      form_name: "mutuelle_sante",
      step_id: "step3",
      step_name: "Mes coordonnées personnelles",
      gamme_professionnelle: codeGamme
    });

    //Mais pourquoi ValidateForm ?
    //validateForm()
    window.scrollTo(0, 0)
    this.props.setAllSubmitCount(this.props.submitCount)
    this.errorRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    // console.log('prevProps', prevProps)
    if (prevProps.submitCount !== this.props.submitCount) {
      this.props.setAllSubmitCount(this.props.submitCount)
    }
    if (
      typeof window !== "undefined" &&
      !this.props.isValid &&
      !this.props.isValidating &&
      prevProps.isSubmitting !== this.props.isSubmitting
    ) {
      const { errors } = this.props
      scrollToFirstError(errors)
    }
  }

  handleChangeWithReset = e => {
    const { resetErrorsAfterSubmitWithErrors, handleChange } = this.props

    if (resetErrorsAfterSubmitWithErrors) {
      resetErrorsAfterSubmitWithErrors(e.target.name)
    }
    handleChange(e)
  }

  render() {
    const {
      errors,
      dirty,
      values,
      touched,
      setFieldValue,
      isErrorSubmitting,
      resetErrorsAfterSubmitWithErrors,
    } = this.props
    return (
      <QuotationWizard.Step>
        <Container className="quotation">
          <Row>
            <Col xs={12} className="quotation-step-3">
              <h2 ref={this.errorRef}>COORDONNÉES PERSONNELLES</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3>Mes coordonnées</h3>
            </Col>
            <Col xs={12} className="quotation-radio form-group">
              <MgcRadioToggleButtonGroupV2
                id="civilite"
                error={errors.civilite}
                touched={touched.civilite}
                withoutOffset={true}
                label={"Civilité *"}
                isErrorSubmitting={isErrorSubmitting}
              >
                <Field
                  component={MgcRadioToggleButtonV2}
                  name="civilite"
                  id="homme"
                  label={"Monsieur"}
                  onChangeWithReset={this.handleChangeWithReset}
                />
                <Field
                  component={MgcRadioToggleButtonV2}
                  name="civilite"
                  id="femme"
                  label={"Madame"}
                  onChangeWithReset={this.handleChangeWithReset}
                />
              </MgcRadioToggleButtonGroupV2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <MgcTextFieldV2
                dirty={dirty}
                errors={errors}
                name="adherentNom"
                label="Nom *"
                placeholder="Ex : DURAND"
                className="form-control"
                withoutOffset={true}
                maxLength={30}
                onChange={e => {
                  if (!hasNumber(e.target.value)) {
                    this.handleChangeWithReset(e)
                  }
                }}
                isErrorSubmitting={isErrorSubmitting}
              />
              <MgcTextFieldV2
                dirty={dirty}
                errors={errors}
                name="adherentPrenom"
                label="Prénom *"
                placeholder="Ex : Jean"
                className="form-control"
                withoutOffset={true}
                maxLength={30}
                onChange={e => {
                  if (!hasNumber(e.target.value)) {
                    this.handleChangeWithReset(e)
                  }
                }}
                isErrorSubmitting={isErrorSubmitting}
              />
            </Col>
          </Row>
          {values.conjointChecked && (
            <>
              <Row>
                <Col xs={12}>
                  <h3>Mon conjoint</h3>
                </Col>
                <Col xs={12} className="quotation-radio form-group">
                  <MgcRadioToggleButtonGroupV2
                    label={"Civilité *"}
                    id="conjointCivilite"
                    error={errors.conjointCivilite}
                    touched={touched.conjointCivilite}
                    withoutOffset={true}
                    isErrorSubmitting={isErrorSubmitting}
                  >
                    <Field
                      component={MgcRadioToggleButtonV2}
                      name="conjointCivilite"
                      id="homme"
                      label={"Monsieur"}
                      onChangeWithReset={this.handleChangeWithReset}
                    />
                    <Field
                      component={MgcRadioToggleButtonV2}
                      name="conjointCivilite"
                      id="femme"
                      label={"Madame"}
                      onChangeWithReset={this.handleChangeWithReset}
                    />
                  </MgcRadioToggleButtonGroupV2>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <MgcTextFieldV2
                    dirty={dirty}
                    errors={errors}
                    name="conjointNom"
                    label="Nom *"
                    placeholder="Ex : DURAND"
                    className="form-control"
                    withoutOffset={true}
                    maxLength={30}
                    onChange={e => {
                      if (!hasNumber(e.target.value)) {
                        this.handleChangeWithReset(e)
                      }
                    }}
                    isErrorSubmitting={isErrorSubmitting}
                  />
                  <MgcTextFieldV2
                    dirty={dirty}
                    errors={errors}
                    name="conjointPrenom"
                    label="Prénom *"
                    placeholder="Ex : Jean"
                    className="form-control"
                    withoutOffset={true}
                    maxLength={30}
                    onChange={e => {
                      if (!hasNumber(e.target.value)) {
                        this.handleChangeWithReset(e)
                      }
                    }}
                    isErrorSubmitting={isErrorSubmitting}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={12}>
              <h5>Je souhaite recevoir mon devis par</h5>
            </Col>
          </Row>
          <Row className="quotation-step-3-checkbox">
            <QuotationEmailsV2
              values={values}
              errors={errors}
              dirty={dirty}
              isErrorSubmitting={isErrorSubmitting}
              onChange={this.handleChangeWithReset}
            />
            <Col xs={12}>
              <Field
                className="check"
                name="courrierChecked"
                type="checkbox"
                component="input"
                id="E-mail"
              />
              <label for="courrier">Courrier</label>
            </Col>
            <div className="col-12">
              <div
                className={classNames("invalid-feedback", {
                  "d-block":
                    !values.courrierChecked &&
                    !values.mailChecked &&
                    errors.choixModeDeReception &&
                    isErrorSubmitting,
                })}
              >
                {errors.choixModeDeReception}
              </div>
            </div>
            {values.courrierChecked ? (
              <Col className={"quot-step-3-adress"}>
                <QuotationAdresseV2
                  errors={errors}
                  dirty={dirty}
                  setFieldValue={setFieldValue}
                  values={values}
                  withoutOffset={true}
                  quotationTns={values.tns}
                  handleChange={this.handleChangeWithReset}
                  isErrorSubmitting={isErrorSubmitting}
                  resetErrorsAfterSubmitWithErrors={
                    resetErrorsAfterSubmitWithErrors
                  }
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {!values.tns && !values.agentTerritorial && (
            <Row>
              <Col xs={12}>
                {/* <MgcTextFieldV2
                  dirty={dirty}
                  errors={errors}
                  name="telephone"
                  label="Mon numéro de téléphone (facultatif)"
                  placeholder="Ex : 0606060606"
                  withoutOffset={true}
                  className="form-control"
                  type={"tel"}
                  onChange={this.handleChangeWithReset}
                  isErrorSubmitting={isErrorSubmitting}
                /> */}
                <MgcPhoneFieldV2
                  dirty={dirty}
                  errors={errors}
                  name="telephone"
                  label="Téléphone"
                  placeholder="Ex : 0606060606"
                  withoutOffset={true}
                  isErrorSubmitting={isErrorSubmitting}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Field
                className="check"
                name="offreMgc"
                type="checkbox"
                component="input"
                id="offre-mgc"
              />
              <span className="font-size-15">
                Si vous souhaitez recevoir, y compris par voie électronique des
                informations sur les offres de la MGC, les événements et les
                jeux-concours organisé par la MGC, cochez cette case.
              </span>
            </Col>
            <Col xs={12} className="m-top-10">
              <Field
                className="check"
                name="offrePartenaire"
                type="checkbox"
                component="input"
                id="offre-partenaire"
              />
              <span className="font-size-15">
                Si vous souhaitez recevoir, y compris par voie électronique des
                informations sur les offres de nos partenaires proposées par la
                MGC(prévoyance...), cochez cette case.
              </span>
            </Col>
          </Row>
        </Container>
      </QuotationWizard.Step>
    )
  }
}

export default QuotationStep3V2
