import React from "react"
import PropTypes from "prop-types"
import { Col } from "react-bootstrap"
import { Field } from "formik"
import MgcTextFieldV2 from "../../global/mgcTextFieldV2"

const QuotationEmailsV2 = ({values, dirty, errors, isErrorSubmitting, onChange}) => (
  <Col xs={12} >
    <Field
      className="check"
      name="mailChecked"
      type="checkbox"
      component="input"
      id="E-mail"
    />
    <label for="E-mail">E-mail</label>
    {values.mailChecked &&  (
      <>
        <MgcTextFieldV2
          dirty={dirty}
          errors={errors}
          name="email"
          label={"Adresse e-mail *"}
          placeholder="Ex : jeandurand@gmail.com"
          className="form-control"
          withoutOffset={true}
          isErrorSubmitting={isErrorSubmitting}
          onChange={onChange}
        />
        <MgcTextFieldV2
          dirty={dirty}
          errors={errors}
          name="confirmEmail"
          label="Confirmer mon adresse e-mail *"
          placeholder="Ex : jeandurand@gmail.com"
          className="form-control"
          withoutOffset={true}
          isErrorSubmitting={isErrorSubmitting}
          onChange={onChange}
        />
      </>
    ) }
  </Col>
)

QuotationEmailsV2.propTypes = {
  values: PropTypes.object,
  dirty: PropTypes.bool,
  errors: PropTypes.object,
}
export default QuotationEmailsV2