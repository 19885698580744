import React from "react"
import { Row, Col } from "react-bootstrap"
import { Field } from "formik"
import MgcSelectFieldV2 from "../../global/mgcSelectFieldV2"
import classNames from "classnames"

class QuotationChildrenTNSV2 extends React.Component {
  render() {
    const {
      tab,
      values,
      dirty,
      errors,
      tabRa,
      setFieldValue,
      isErrorSubmitting,
      resetErrorsAfterSubmitWithErrors,
      handleChangeWithReset
    } = this.props
    return (
      <div>
        <Row>
          <Col xs={11}>
            <label>Nombre d'enfants</label>
            <Field
              name="nbEnfantsPlus1an"
              component="select"
              className={classNames("form-control", {"is-invalid" : !values.nbEnfantsPlus1an && isErrorSubmitting})}
              onChange={handleChangeWithReset}
            >
              <option key="plop" value="null">
                - 0 enfant -
              </option>
              {tab.map(e => {
                return (
                  <option key={e.id} value={e.id}>
                    {e.libelle}
                  </option>
                )
              })}
            </Field>
            <div className="invalid-feedback">champ obligatoire</div>
          </Col>
          <Col xs={1} className={"quotation-childrenTNS"}>
            <span
              className={`${
                !values.nbEnfantsPlus1an && isErrorSubmitting
                  ? "fa fa-times text-danger"
                  : ""
              } fa-2x fa`}
            />
          </Col>
        </Row>
        {values.nbEnfantsPlus1an > 1 ? (
          <span>Régime d'assurance maladie du 1er enfant (le + âgé)</span>
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an == 1 || values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectFieldV2
            label={`${
              values.nbEnfantsPlus1an > 1
                ? "Régime d'assurance maladie du 1er enfant (le + âgé) *"
                : "Régime de sécurité sociale *"
            }`}
            dirty={dirty}
            name="regimeAssuranceMalEnfant1"
            errors={errors}
            firstOption="- Sélectionner le régime -"
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
            isErrorSubmitting={isErrorSubmitting}
            onChange={() => {resetErrorsAfterSubmitWithErrors("regimeAssuranceMalEnfant1")}}
          />
        ) : (
          ""
        )}
        {values.nbEnfantsPlus1an == 2 ? (
          <MgcSelectFieldV2
            dirty={dirty}
            label="Régime d'assurance maladie du 2ème enfant *"
            name="regimeAssuranceMalEnfant2"
            errors={errors}
            firstOption="- Sélectionner le régime -"
            className="form-control"
            table={tabRa}
            setFieldValue={setFieldValue}
            isErrorSubmitting={isErrorSubmitting}
            onChange={() => {resetErrorsAfterSubmitWithErrors("regimeAssuranceMalEnfant2")}}
          />
        ) : (
          ""
        )}
      </div>
    )
  }
}

export default QuotationChildrenTNSV2
