import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import GAnalyticsService from "../../../services/gAnalyticsService"
import QuotationWizardV2 from "./quotationWizardV2"
import "./quotationStep4V2.css"
import { triggerWebcallback } from "../../../services/webcallback/webCallbackService"

class QuotationStep4V2 extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    const { codeGamme } = this.props.values
    if (codeGamme) {
      GAnalyticsService.sendEvent("Devis santé", "Envoi de devis")
    }

    console.log("codeGamme");
    console.log(codeGamme);

    window.dataLayer.push({
          event: "quotation_form",
          form_name: "mutuelle_sante",
          step_id: "step4",
          step_name: "Mon devis",
          gamme_professionnelle: codeGamme
      });
  }
  onClickWebcallbackLink() {
    triggerWebcallback()
  }

  render() {
    return (
      <QuotationWizardV2.Step>
        <Container className="quotation-step-4 quotation-step-4-v2">
          <h3 className="pt-xl-4 step4-title">
            VOTRE DEMANDE A ÉTÉ PRISE EN COMPTE !
          </h3>
          <Row>
            <Col xs={12}>
                <p>
                Merci pour cette demande de devis et de l'intérêt que 
                vous portez à la mutuelle MGC. Votre demande a bien été prise en 
                compte et vous recevrez votre devis dans les meilleurs délais.              
                </p>
                <p>
                Si vous désirez de plus amples informations, n'hésitez pas à
                nous contacter par email (
                <a href="https://www.mutuellemgc.fr/contact/" target="_blank">
                  via la page contact
                </a>
                ) ou par téléphone au{" "}
                <a href="tel:0140785710">01 40 78 57 10</a> (du lundi au jeudi de 9h00 à 18h00 et le vendredi de 9h00 à 17h30) ou au 32 80 06 pour les salariés SNCF.
                {/*
                ou sur simple demande de{" "}
                <a
                  className="webcallback-link LnkWcbForm-trigger"
                  onClick={this.onClickWebcallbackLink}
                >
                  rappel gratuit immédiat ou différé.
                </a>
                */}
              </p>
              <p>
                Nous serons ravis de vous fournir de plus amples informations et
                de répondre à toutes vos questions.
              </p>
            </Col>
          </Row>
        </Container>
      </QuotationWizardV2.Step>
    )
  }
}

export default QuotationStep4V2
