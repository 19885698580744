import React from "react"
import Layout from "../components/layout"
import QuotationV2 from "../components/quotationV2"
import QuotationPromos from "../components/quotation/quotationPromos"

class QuotationV2Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      regimeAssurance: null,
      situationProfessionelle: null,
      thisStep: 0,
    }
  }

  toKnowStep = () => {
    this.setState({ thisStep: this.state.step++ })
  }

  render() {
    return (
      <Layout
        titleSeo="Devis mutuelle santé : immédiat, gratuit et sans engagement"
        keywordsSeo={["Devis", "mutuelle", "santé"]}
        canonical={this.props.pageContext.canonicalUrl}
        description={
          "Devis mutuelle santé en trois clics : comparez facilement les tarifs afin de choisir la complémentaire santé adaptée à vos besoins et à votre budget."
        }
      >
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink breadcrumbItemLinkMiddle">
                    Devis santé
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <section className="pageHeader callback">
          <div className="quotation-title">
            <h1 className="pageHeaderTitle ">DEVIS MUTUELLE SANTÉ</h1>
          </div>
        </section>

        {this.state.thisStep === 0
          ? <QuotationPromos/>
          : ""}

        <section
          className={
            this.state.thisStep === 0
              ? "quotation-body-step-1"
              : "quotation-body-other-step"
          }
        >
          <div className="container-quotation">
            <div className="quotation-form col-xs-12">
              <div className="header-form-img">
                {/* <img src={quotationIcon} alt="" /> */}
              </div>
              <div className="row">
                <div className="col">
                  <QuotationV2 props={this.props} thisStep={this.toKnowStep} />
                </div>
              </div>
            </div>
            <div className="quotation-footer">
              <h6>Le devis mutuelle complémentaire santé MGC sans engagement</h6>
              <div>Vous souhaitez faire une simulation d'assurance santé afin de choisir la meilleure complémentaire santé ? La MGC vous permet de comparer ses garanties santé pour faire le meilleur choix en prenant en compte vos besoins et votre budget.</div>
              <h6>Votre devis santé en ligne en quelques clics</h6>
              <div>Les garanties santé les plus adaptées à votre situation sont tarifées directement et affichées à l'écran. Seules les informations nécessaires au calcul de votre devis sont à renseigner. Si vous souhaitez un devis pour d'autres garanties, n'hésitez pas à nous contacter.</div>
              <h6>Votre devis santé par téléphone, du lundi au jeudi de 9h00 à 18h00 et le vendredi de 9h00 à 17h30</h6>
              <div>Les conseillers de la mutuelle MGC sont à votre disposition au <a style={{color: '#2b6b8b', textDecoration: 'underline'}} href="tel:0140785710">01 40 78 57 10</a> (prix d'un appel local), pour simuler votre cotisation santé et vous conseiller sur le choix de la formule la plus adaptée à votre situation.</div>
              <h6>Votre devis santé en agence</h6>
              <div>Pour rencontrer un conseiller MGC et établir un devis santé personnalisé, rendez-vous en agence ! NB : En cas de besoin, vous pouvez demander une aide pour réduire votre cotisation. <a title="Aides financement mutuelle" href="/faq/aides-financement-mutuelle-sante/" target="_blank" rel="noopener" style={{color: '#2b6b8b', textDecoration: 'underline'}}>Voir les aides existantes et les conditions pour en bénéficier</a>.</div>
              <div> </div>
              <br />
              <h3 style={{fontFamily: '"Yanone Kaffeesatz"', fontSize: '28px', fontWeight: 400, padding: '20px 0px 10px', textAlign: 'center', color: 'black'}}>Comment obtenir un devis de mutuelle santé ?</h3>
              <p style={{textAlign: 'center'}}>3 étapes pour obtenir le devis qui correspondant le mieux à vos besoins : </p>
              <div style={{width: '100%', textAlign: 'center', marginTop: '20px', paddingBottom: '10px'}}>
                <div id="blocnumber" style={{float: 'left', width: '30%', height: 'auto', backgroundImage: 'none', backgroundRepeat: 'repeat', backgroundPosition: 'top left', backgroundAttachment: 'scroll'}}>
                  <div style={{width: '40px', height: '40px', borderRadius: '100%', backgroundColor: '#28a745', color: '#fff', textAlign: 'center', fontSize: '20px', overflow: 'hidden', margin: '0 auto'}}>
                    <p style={{margin: '5px', fontSize: '20px'}}>1</p>
                  </div>
                  <p style={{fontSize: '16px', color: '#28a745', padding: '20px', fontFamily: 'Helvetica Neue LT Std'}}><a style={{textDecoration: 'none', color: '#28a745'}} href="#Etape1">Informations sur l’adhérent principal et les autre(s) bénéficiaire(s)</a></p>
                </div>
                <div id="blocnumber" style={{display: 'inline-block', width: '30%', height: 'auto', backgroundImage: 'none', backgroundRepeat: 'repeat', backgroundPosition: 'top left', backgroundAttachment: 'scroll', margin: '0 auto 0 auto'}}>
                  <div style={{width: '40px', height: '40px', borderRadius: '100%', backgroundColor: '#28a745', color: '#fff', textAlign: 'center', fontSize: '20px', overflow: 'hidden', margin: '0 auto'}}>
                    <p style={{margin: '5px', fontSize: '20px'}}>2</p>
                  </div>
                  <p style={{fontSize: '16px', color: '#28a745', padding: '20px', fontFamily: 'Helvetica Neue LT Std'}}><a style={{textDecoration: 'none', color: '#28a745'}} href="#Etape2">Répondre au questionnaire déterimant les besoins pour les postes importants</a></p>
                </div>
                <div id="blocnumber" style={{float: 'right', width: '30%', height: 'auto', backgroundImage: 'none', backgroundRepeat: 'repeat', backgroundPosition: 'top left', backgroundAttachment: 'scroll'}}>
                  <div style={{width: '40px', height: '40px', borderRadius: '100%', backgroundColor: '#28a745', color: '#fff', textAlign: 'center', fontSize: '20px', overflow: 'hidden', margin: '0 auto'}}>
                    <p style={{margin: '5px', fontSize: '20px'}}>3</p>
                  </div>
                  <p style={{fontSize: '16px', color: '#28a745', padding: '20px', fontFamily: 'Helvetica Neue LT Std'}}><a style={{textDecoration: 'none', color: '#28a745'}} href="#Etape3">Choisir la garantie la plus adaptée à ses besoins et à son budget</a></p>
                </div>
              </div>
              <br/>
              <h4 style={{fontSize: '17px', color: '#de004d', fontWeight: '400'}}>1) <a id="Etape1" />Renseigner les informations sur l’adhérent principal et les autre(s) bénéficiaire(s)</h4>
              <p>Pour obtenir le devis correspond le mieux à votre profil, il est important de bien renseigner les informations nécessaires :</p>
              <ul>
                <li>Nom prénom</li>
                <li>Coordonnées</li>
                <li>Date de naissance</li>
                <li>Régime d’Assurance Maladie Obligatoire</li>
                <li>Situation professionnelle…</li>
              </ul>
              <p>Ainsi que les informations sur les éventuels <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Ayants-droits" href="/faq/comment-inscrire-beneficiaire-contrat-sante/" target="_blank" rel="noopener">ayants-droits</a> à inscrire sur le contrat santé (conjoint, enfant(s)) afin qu’ils bénéficient des mêmes garanties et prestations que le souscripteur.</p>
              <h4 style={{fontSize: '17px', color: '#de004d', fontWeight: '400'}}>2) <a id="Etape2" />Estimer les besoins santé</h4>
              <p>Evaluez vos besoins de santé ainsi que ceux des personnes à couvrir pour chaque poste de santé (<a style={{color: '#2b6b8b', textDecoration: 'underline'}}title="Dépassements honoraires médecins" href="/faq/remboursements-depassement-honoraire-comment-ca-marche/" target="_blank" rel="noopener">consultations de spécialistes</a>, <a style={{color: '#2b6b8b', textDecoration: 'underline'}}title="Remboursements dentaire" href="/faq/remboursements-soins-dentaires/" target="_blank" rel="noopener">dentaire</a>, <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Remboursements optique" href="/faq/optique-remboursements-soins-secu-mutuelle/" target="_blank" rel="noopener">optique</a>, <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Remboursements hospitalisation" href="/faq/remboursements-hospitalisation-comment-ca-marche/" target="_blank" rel="noopener">hospitalisation</a>, <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Remboursements soins courants" href="/faq/remboursements-soins-courants-comment-ca-marche/" target="_blank" rel="noopener">soins courants</a>…). Il est important de prendre en considération les postes de soins mal remboursés, voire non pris en charge par l’Assurance Maladie obligatoire comme les soins dentaires ou optiques, l’<a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Remboursements automédication" href="/faq/automedication-me-soigner-seul/" target="_blank" rel="noopener">automédication</a>, les <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Remboursements médecines alternatives" href="/faq/prise-en-charge-medecine-alternative/" target="_blank" rel="noopener">médecines alternatives</a>, les contraceptifs…</p>
              <p>Vous l’aurez compris, il est indispensable de bien définir le niveau de remboursement souhaité en indiquant <strong>vos besoins importants</strong>, tout en prenant en compte les éventuels autres bénéficiaires rattachés à votre contrat santé.</p>
              <h4 style={{fontSize: '17px', color: '#de004d', fontWeight: '400'}}>3) <a id="Etape3" />Choisir une garantie adaptée</h4>
              <p>En matière de santé les besoins ne sont pas les mêmes pour tous. Une famille de trois enfants n’aura en effet pas les mêmes dépenses de santé qu’un jeune actif. </p>
              <p>En fonction des informations remplies précédemment la mutuelle est en mesure de vous proposer un tarif et une garantie adaptés à votre situation :</p>
              <ul>
                <li><strong>Un tarif clair</strong> : vous connaissez immédiatement le tarif mensuel et pouvez ainsi déterminer si le montant des cotisations est adapté.</li>
                <li><strong>Un large choix de formules</strong> : comparez facilement les niveaux de remboursements des différentes formules.</li>
              </ul>
              <p>Le devis en ligne est ouvert aux <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Particuliers" href="/particulier/" target="_blank" rel="noopener">particuliers</a>, aux <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Cheminots" href="/cheminot-en-activite/" target="_blank" rel="noopener">cheminots</a>, aux <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Professionnels indépendants" href="/professionnel-independant/" target="_blank" rel="noopener">travailleurs indépendants</a> et aux <a style={{color: '#2b6b8b', textDecoration: 'underline'}} title="Agents territoriaux" href="/agent-territorial/" target="_blank" rel="noopener">agents territoriaux</a>.</p>
              <hr />
              <p>
                <strong>Les avantages de faire une demande de devis de complémentaire santé en ligne :</strong>
              </p>
              <ul>
                <li><strong>Un tarif immédiat</strong> : à n’importe quel moment et où vous voulez, il suffit juste d’une connexion internet.</li>
                <li><strong>Modifiable à tout moment</strong> : vous pouvez revenir en arrière, ajouter ou retirer un bénéficiaire, revoir le questionnaire des besoins, accéder à toutes les formules… en seulement quelques clics.</li>
                <li><strong>Une adhésion rapide</strong> : à la dernière étape d’une demande de devis, vous pouvez choisir d'adhérer en ligne, les informations préremplies sont alors conservées et vous n’avez plus qu’à valider pour souscrire.</li>
              </ul>
              <p>A noter : votre devis de complémentaire santé est valable <strong>jusqu’à la fin de l’année en cours</strong> à compter de sa date d’édition.</p>
              <p> 
              <br/>
              </p>
              
              <div style={{textAlign: 'center!important'}}>
                <a style={{cursor: 'pointer', border: '1px solid #fff', textTransform: 'uppercase', color: '#fff', padding: '12px', backgroundColor: '#e0004d', textAlign: 'center', fontFamily: '"Yanone Kaffeesatz"', textDecoration: 'none', fontSize: '24px', fontWeight: 300}} href="#">Demande de devis</a>
              </div>
            </div>
          <p> </p>
        </div>

        </section>
      </Layout>
    )
  }
}

export default QuotationV2Page
